import * as React from "react";
import {useEffect, useState} from "react";
import apiCall from "../Api";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import {Button} from "@material-ui/core";
import {useHistory} from "react-router";
import useTab from "use/useTab";
import Tab from "microcomponents/tab";


export default function ResetPassword(props){

    // props.setTitle('Change Password')
    const classes = styles();
    const history = useHistory();
    const user = sessionStorage.getItem('user')?JSON.parse(sessionStorage.getItem('user')):undefined;

 
    const [ps, setPs] = useState({pass:'',repass:''});
    const setItem = (code, val)=>{
        ps[code]=val;
        setPs({...ps})
    }
   
    const changePassword = (e) => {
 
       
        const model={
            rePassword:ps.repass,
            password:ps.pass,
            id: Number(user.id)   
        }
        const requestOptions = {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(model)
        };
        fetch(window.API_HOST + '/customers/change_password', requestOptions)
          .then(response => response.json())
          .then(data => {
            if (data.status === 'FAILURE' || data.error) {
              // setError(data.message);
              // sessionStorage.removeItem('token');
              // sessionStorage.removeItem('user');
              // setToken('');
              alert('Failure change passwords');
            } else { 
              // sessionStorage.setItem('token', data.response.token);
              // if(data.response.user){
              //   sessionStorage.setItem('user', JSON.stringify(data.response.user));
              // }
              // setError('');
              // setToken(data.response.token);
            //   history.push('/login' )
            alert('password changed successfully');
            setPs({...{pass:'',repass:''}})
            }
          });
       }
       
      
    return(
        <div style={{marginTop:"2%"}} >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <span>Enter New Password for Change it.</span>
                </Grid>
                {!user&&
                    <Grid item xs={12}>
                        <span style={{color: 'red'}}>Please Log out and try again.</span>
                    </Grid>
                }
                <Grid item xs={12}>
                    <TextField
                        required
                        type='password'
                        style={{width: 300}}
                        className={classes.requiredField}
                        helperText={ps.pass?ps.repass&&ps.pass!==ps.repass?'Password and Re-Password aren\'t same':'':'Password is Required.'}
                        label='Password'
                        value={ps.pass}
                        variant='outlined'
                        onChange={(p) => setItem('pass', p.target.value)}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        type='password'
                        style={{width: 300}}
                        className={classes.requiredField}
                        helperText={ps.repass?ps.pass&&ps.pass!==ps.repass?'Password and Re-Password aren\'t same':'':'Re-Password is Required.'}
                        label='Re-Password'
                        value={ps.repass}
                        variant='outlined'
                        onChange={(p) => setItem('repass', p.target.value)}/>
                </Grid>
            </Grid>
            <hr/>
            <Button variant="contained" color="primary" onClick={() => changePassword()}
                    disabled={!(ps.pass&&ps.repass&&ps.pass===ps.repass)}>
                Change Password
            </Button>
          
        </div>
    )
}

const styles = makeStyles((theme) => ({
    searchContainer: {
        width: '100%',
        marginBottom: '10px',
    },
    success: {
        backgroundColor: '#5cb85c',
        "&:hover": {
            backgroundColor: '#4fa14f',
        }
    },
    requiredField: {
        "& .MuiFormHelperText-root":{
            color: 'red'
        }
    }
}));
