import * as React from 'react';
import {useEffect, useState} from 'react';
import {DataGrid} from '@material-ui/data-grid';
import {Button, Grid} from "@material-ui/core";
import {useHistory} from "react-router";
import apiCall from "../../Api";
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import AddCircle from "@material-ui/icons/AddCircle";

const initRows = [];

export default function DataTable(props) {
    const [rows, setRows] = useState(initRows);
    const [type, setType] = useState('APPROVED');
    const [accountTypes, setAccountTypes] = useState([])
    const history = useHistory();
    const classes = styles();
    const user = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : null;
    const isAdmin = user === null;
    const refresh = (typ) => apiCall('/admins/accounts/accounts/status/'+(typ?typ:type)).then(x => {
        let res = x.response
        if(!isAdmin){
            res = res.filter(a=> a.user.username === user.username)
        }
        setRows(res)
    });
    const getAccountTypeTitle=(c)=>{
        if(accountTypes&&accountTypes.length>0){
            const at = accountTypes.filter(a=>a.code === c)
            if(at&&at.length>0){
                return at[0].title
            }else{
                return c;
            }
        }else{
            return c;
        }
    }
    const editAccount = (id)=> {
        history.push('/accounts/'+id);
    }
    const columns = [
        {field: 'id', headerName: 'ID', width: 70},
        {
            field: 'accountNo',
            headerName: 'Account No',
            width: 130
        },
        {
            field: 'accountType', headerName: 'Account Type', width: 130,renderCell: (params) => (getAccountTypeTitle(params.getValue('accountType')))
        },
        {
            field: 'adminComment',
            headerName: 'Admin Comment',
            width: 180
        },
        {
            field: 'createDate',
            headerName: 'Create Date',
            width: 180
        },
        {
            field: 'currency',
            headerName: 'Currency',
            width: 130,
        },
        {
            field: 'status',
            headerName: 'Status',
            sortable: false,
            width: 110
        },
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            width: 250,
        }
    ];
    const approvedList = ['APPROVED', 'NEW', 'REJECT'];
    props.setTitle('Accounts')
    useEffect(() => {
        apiCall('/admins/account-types').then(r=>{
            setAccountTypes(r.response)
        })
        refresh()
    }, []);
    return (
        <div style={{height: 400, width: '100%'}}>
            <Grid container spacing={2}>
                {isAdmin&&
                <Grid item xs={12} sm={6}>
                    <div className={classes.searchContainer}>
                        <Button
                            className={classes.success}
                            variant="contained"
                            color="primary"
                            startIcon={<AddCircle/>}
                            onClick={() => history.push('/accounts/create')}>Create Account</Button>
                    </div>
                </Grid>
                }
                <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="status">Status</InputLabel>
                        <Select label="Status" value={type}
                                style={{width: 300}}
                                onChange={(p) => {
                                    setType(p.target.value);
                                    refresh(p.target.value)
                                }}
                                inputProps={{
                                    name: 'status',
                                    id: 'status',
                                }}>
                            {approvedList.map(i=> (
                                <MenuItem value={i}>{i}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <DataGrid rows={rows} columns={columns} pageSize={5}/>
        </div>
    );
}

const styles = makeStyles((theme) => ({
    searchContainer: {
        width: '100%',
        marginBottom: '10px',
    },
    success: {
        backgroundColor: '#5cb85c',
        "&:hover": {
            backgroundColor: '#4fa14f',
        }
    }
}));
