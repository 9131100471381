import * as React from 'react';
import {useEffect, useState} from 'react';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import {AccountCircle} from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import apiCall from "../../Api";
import {makeStyles} from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {Button} from "@material-ui/core";
import {useHistory} from "react-router";

const initUsers = []
const initCard = {
    user: {id: null},
    account: {accountNo:''},
    cardType: {},
    cardNo: '',
    expMonth: '',
    expYear: '',
    status: 'Active'
};

export default function EditCard(props) {

    props.setTitle('Edit Card');
    const {id} = props.match.params;
    const classes = useStyles();
    const history = useHistory();
    const [card, setCard] = useState(initCard);
    const [users, setUsers] = useState(initUsers);
    const [accounts, setAccounts] = useState(initUsers)
    const [types,setTypes] = useState(initUsers);

    const setItem=(code,val)=>{
        card[code]=val;
        setCard({...card});
    }
    const loadAccounts = (user) => {
        card.user = user?user:{id:null}
        setCard({...card})
        setAccounts(initUsers)
        if (!user){
            card.account= {accountNo: ''};
            setCard({...card});
            return;
        }
        apiCall(`/admins/users/${user.id}/accounts`).then(x => setAccounts(x.response))
    }
    const save=()=>{
        const entity = {
            accountNo: card.account.accountNo,
            cardNo: card.cardNo,
            cardTypeId: card.cardType.id,
            expMonth: card.expMonth,
            expYear: card.expYear,
            status: card.status
        }
        let url = '/admins/cards';
        let method = 'POST'
        if(id!=='0'){
            url += '/'+id;
            method='PUT'
        }
        apiCall(url, entity, method).then(r=> history.goBack())
    }

    useEffect(() => {
        apiCall('/admins/customers/status/APPROVED').then(x => setUsers(x.response));
        apiCall('/admins/card-types').then(r=>setTypes(r.response))
        if(id!=='0'){
            apiCall('/admins/cards').then(r=>{
                setCard({...r.response.filter(c=>c.id.toString() === id)[0]})
            })
        }
    }, []);

    return (
        <div>
            <Grid container spacing={3} item xs={12} sm={8}>
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        fullWidth
                        value={card.user}
                        onChange={(e, p) => loadAccounts(p)}
                        options={users}
                        classes={{
                            option: classes.option,
                        }}
                        getOptionLabel={(option) => option.firstName?`${option.firstName} ${option.lastName}`:option.username}
                        renderOption={(option) => (
                            <React.Fragment>
                                {option.firstName?`${option.firstName} ${option.lastName}`:option.username}
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="User"
                                variant="outlined"
                                inputProps={{
                                    ...params.inputProps,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccountCircle/>
                                        </InputAdornment>
                                    ),
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                                helperText={"Incorrect entry."}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        fullWidth
                        noOptionsText="Select User For Load Accounts."
                        value={card.account}
                        onChange={(e, p) => setItem('account',p)}
                        options={accounts}
                        classes={{
                            option: classes.option,
                        }}
                        getOptionLabel={(option) => option.accountNo?`${option.accountNo} ${option.module} (${option.currency})`:option.accountNo}
                        renderOption={(option) => (
                            <React.Fragment>
                                {`${option.accountNo} ${option.module} (${option.currency})`}
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Account"
                                variant="outlined"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                                helperText={"Incorrect entry."}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        fullWidth
                        value={card.cardType}
                        onChange={(e, p) => setItem('cardType',p)}
                        options={types}
                        classes={{
                            option: classes.option,
                        }}
                        getOptionLabel={(option) => option.title}
                        renderOption={(option) => (
                            <React.Fragment>
                                {option.title}
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Type"
                                variant="outlined"
                                inputProps={{
                                    ...params.inputProps,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccountCircle/>
                                        </InputAdornment>
                                    ),
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                                helperText={"Incorrect entry."}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Card No."
                        value={card.cardNo}
                        onChange={(p) => setItem('cardNo',p.target.value)}
                        helperText="Incorrect entry."
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                        label="Expiration Month"
                        value={card.expMonth}
                        placeholder="MM"
                        onChange={(p) => setItem('expMonth', p.target.value)}
                        helperText="Incorrect entry."
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                        label="Expiration Year"
                        placeholder="yyyy"
                        value={card.expYear}
                        onChange={(p) => setItem('expYear', p.target.value)}
                        helperText="Incorrect entry."
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="status">Status</InputLabel>
                        <Select label="Status" value={card.status}
                                onChange={(p) => {
                                    card.status = p.target.value;
                                    setCard({...card})
                                }}
                                inputProps={{
                                    name: 'status',
                                    id: 'status',
                                }}>
                            <MenuItem value={'Active'}>Active</MenuItem>
                            <MenuItem value={'Blocked'}>Blocked</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <hr/>
            <Button variant="contained" color="primary" onClick={() => save()}
                    disabled={!(card.user&&card.user.username&&card.account&&card.account.accountNo&&card.cardType&&card.cardType.id&&card.cardNo&&card.expMonth&&card.expYear)}>
                Save
            </Button>
            &nbsp;
            <Button variant="outlined" onClick={() => history.goBack()}>
                Cancel
            </Button>
        </div>
    )
}


const useStyles = makeStyles((theme) => ({
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '100%',
    },
}));
