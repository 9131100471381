import React, { useState } from "react";
import AppBar from "../appbar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Link } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  marginHeader: {
    marginTop: 20,
  },
  margin: {
    marginTop: 10,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: "#32266e;",
    color: "white",
    // height:"100px"
  },
  linkLogo: {
    textAlign: "center",
    fontSize: "25px",
  },
  link: {
    color: "white",
  },
  menu: {
    color: "white",
  },

  child: {
    marginTop: "4%",
  },
  slid: {
    height: "200px",
  },
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));
export default function Home(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);

  return (
    <div className={classes.root}>
      <AppBar />
      <Grid container className={classes.child}>
        <Grid item >
          <Grid container justify="center">
            <Grid item className={classes.slid} xs={6}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography
                    align="center"
                    variant="h6"
                    className={classes.marginHeader}
                  >
                    TERMS AND CONDITIONS
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.margin}>
                    Last updated October, 1st, 2023{" "}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography className={classes.margin}>
                    The General Terms and Conditions (hereinafter “Agreement” or
                    “Terms and Conditions” or “Legal Terms”) constitute the main
                    set of Peace Treasury terms and conditions for the provision
                    and use of its services. They apply to each electronic form
                    or contract executed by users and/or members who use Peace
                    Treasury services and, unless expressly stipulated otherwise
                    in the Peace Treasury Contractual Documentation.
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography className={classes.margin}>
                    We operate the website http://www.peacetreasury.com (the
                    "Site"), as well as any other related products and services
                    that refer or link to these legal terms.
                  </Typography>
                  <Typography>
                    We recommend that you print a copy of these Legal Terms for
                    your records.
                  </Typography>
                </Grid>

                <Grid item className={classes.margin}>
                  <Grid container direction="column" spacing={1} wrap="nowrap">
                    <Grid item>
                      <Typography color="primary">
                        1. PRELIMINARY PROVISIONS
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant="caption">
                        a. You must agree to all of the terms of this Agreement.
                        If you do not agree to or accept all of the terms of
                        this Agreement, please immediately discontinue access
                        to, and use of, all Peace Treasury services. This
                        Agreement applies to all users and investors.
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant="caption">
                        b. If you are under the age of eighteen (18) or the
                        legal age for entering legally binding contracts under
                        applicable laws, you are not permitted to use any of
                        Peace Treasury services. By continuing to access or use
                        the Site, you indicate that you are 18 years of age or
                        older or have the legal capacity to enter legally
                        binding contracts under applicable laws.
                        Misrepresentation of your age to gain access to any of
                        Peace Treasury’s services is a breach of this Agreement.
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant="caption">
                        c. Supplemental terms and conditions or documents that
                        may be posted on the Services from time to time are
                        hereby expressly incorporated herein by reference. We
                        reserve the right, in our sole discretion, to make
                        changes or modifications to these Legal Terms at any
                        time and for any reason. We will alert you about any
                        changes by updating the "Last updated" date of these
                        Legal Terms, and you waive any right to receive specific
                        notice of each such change. It is your responsibility to
                        periodically review these Legal Terms to stay informed
                        of updates. You will be subject to, and will be deemed
                        to have been made aware of and to have accepted, the
                        changes in any revised Legal Terms by your continued use
                        of the Services after the date such revised Legal Terms
                        are posted.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item className={classes.margin}>
                  <Grid container direction="column" spacing={1} wrap="nowrap">
                    <Grid item>
                      <Typography color="primary">2. OUR SERVICES</Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant="caption">
                        The information provided when using the Services is not
                        intended for distribution to or use by any person or
                        entity in any jurisdiction or country where such
                        distribution or use would be contrary to law or
                        regulation or which would subject us to any registration
                        requirement within such jurisdiction or country.
                        Accordingly, those persons who choose to access the
                        Services from other locations do so on their own
                        initiative and are solely responsible for compliance
                        with local laws, if and to the extent local laws are
                        applicable.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item className={classes.margin}>
                  <Grid container direction="column" spacing={1} wrap="nowrap">
                    <Grid item>
                      <Typography color="primary">
                        3. INTELLECTUAL PROPERTY RIGHTS
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography>Our intellectual property</Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant="caption">
                        We are the owner or the licensee of all intellectual
                        property rights in our Services, including all source
                        code, databases, functionality, software, website
                        designs, audio, video, text, photographs, and graphics
                        in the Services (collectively, the "Content"), as well
                        as the trademarks, service marks, and logos contained
                        therein (the "Marks").
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant="caption">
                        Our Content and Marks are protected by copyright and
                        trademark laws (and various other intellectual property
                        rights and unfair competition laws) and treaties in the
                        United States and around the world.
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography>Your use of our Services</Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant="caption">
                        Subject to your compliance with these Legal Terms,
                        including the "PROHIBITED ACTIVITIES" section below, we
                        grant you a non-exclusive, non-transferable, revocable
                        license to:{" "}
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant="caption">
                        . access the Services; and{" "}
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant="caption">
                        . download or print a copy of any portion of the Content
                        to which you have properly gained access.{" "}
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant="caption">
                        solely for your personal, non-commercial use or internal
                        business purpose.
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant="caption">
                        Except as set out in this section or elsewhere in our
                        Legal Terms, no part of the Services and no Content or
                        Marks may be copied, reproduced, aggregated,
                        republished, uploaded, posted, publicly displayed,
                        encoded, translated, transmitted, distributed, sold,
                        licensed, or otherwise exploited for any commercial
                        purpose whatsoever, without our express prior written
                        permission.
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant="caption">
                        If you wish to make any use of the Services, Content, or
                        Marks other than as set out in this section or elsewhere
                        in our Legal Terms, please address your request to:
                        info@peacetreasury.com.
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant="caption">
                        If we ever grant you the permission to post, reproduce,
                        or publicly display any part of our Services or Content,
                        you must identify us as the owners or licensors of the
                        Services, Content, or Marks and ensure that any
                        copyright or proprietary notice appears or is visible on
                        posting, reproducing, or displaying our Content.
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography variant="caption">
                        We reserve all rights not expressly granted to you in
                        and to the Services, Content, and Marks.
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption">
                        Any breach of these Intellectual Property Rights will
                        constitute a material breach of our Legal Terms and your
                        right to use our Services will terminate immediately.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item className={classes.margin}>
                  <Grid container direction="column" spacing={1} wrap="nowrap">
                    <Grid item>
                      <Typography>Your submissions</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption">
                        Please review this section and the "PROHIBITED
                        ACTIVITIES" section carefully prior to using our
                        Services to understand the (a) rights you give us and
                        (b) obligations you have when you post or upload any
                        content through the Services.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
