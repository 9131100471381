import * as React from 'react';
import {useEffect, useState} from 'react';
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import apiCall from "../../Api";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import {useHistory} from "react-router";
import NumberFormat from "react-number-format";

const initRows = [];

export default function CurrencyList(props){
    const classes = styles();
    const history = useHistory();
    const [items, setItems] = useState(initRows);
    const refresh = () => apiCall('/admins/currencies').then(x => {
        const resp = x.response;
        const itms = currencies.map(item=>{
            const itm = resp.filter(it=> it.currency === item);
            return {
                ccy: item,
                rate: itm.length>0?itm[0].rate:0,
                isBase: itm.length>0?itm[0].isBase:false,
                active: itm.length>0}
        })
        setItems(itms);
    });
    const changeCheckbox=(ccy,p, type)=>{
        setItems(items.map(i=>{
            if(i.ccy === ccy){
                if(type === 'ch'){
                    i.active = p;
                }else{
                    i.rate = p
                }
            }
            return i;
        }));
    }
    const save = ()=>{
        apiCall('/admins/currencies',{items: items.filter(i=>i.rate>0).map(i=>{return {ccy: i.ccy, rate: Number(i.rate)}})}, 'POST').then(r=> {
            if(r.staus === 'SUCCESS')
                alert('Saved Successfully');
            refresh();
        });
    }
    useEffect(()=> {refresh()},[]);
    props.setTitle('Currency List');
    return(
        <div style={{width: '100%', minWidth: 300}}>
            <Grid container spacing={0}>
                <Grid item xs={2}>
                    <b>Default Currency:</b>
                    <hr/>
                </Grid>
                <Grid item xs={10}>
                    <b>{items.find(c=>c.isBase)?items.find(c=>c.isBase).ccy:''}</b>
                    <hr/>
                </Grid>
                <Grid item xs={2}><b>Currency Code</b></Grid>
                {/*<Grid item xs={1}><b>Active</b></Grid>*/}
                <Grid item xs={10}><b>Rate</b></Grid>
            {items.filter(c=>!c.isBase).map(c=> (
                <React.Fragment key={c.ccy}>
                    <Grid item xs={2}><InputLabel>{c.ccy}</InputLabel></Grid>
                    {/*<Grid item xs={1}>*/}
                    {/*    <Checkbox*/}
                    {/*        checked={c.active}*/}
                    {/*        onChange={(e,p)=>changeCheckbox(c.ccy, p, 'ch')}*/}
                    {/*        name="checkedB"*/}
                    {/*        color="primary"*/}
                    {/*    />*/}
                    {/*</Grid>*/}
                    <Grid item xs={10}>
                        <TextField
                            variant="outlined"
                            style={{marginBottom: 1}}
                            value={c.rate}
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                            }}
                            onChange={(e) => changeCheckbox(c.ccy, e.target.value, 'input')}
                        />
                    </Grid>
                </React.Fragment>
            ))}
            </Grid>
            <hr/>
            <Button variant="contained" color="primary" onClick={() => save()}
                    style={{background: '#4fa14f'}}>
                Save Changes
            </Button>
            &nbsp;
            <Button variant="outlined" onClick={() => history.goBack()}>
                Cancel
            </Button>
        </div>
    )
}

const currencies = ['AUD','BGN','BRL','CAD','CHF','CNY','CZK','DKK','EUR','GBP','HKD','HRK','HUF','ILS','INR','JPY','MXN','NOK','NZD','PHP','PLN','RON','RUB','SEK','SGD','THB','TRY','USD','ZAR','BTC'];
const styles = makeStyles((theme) => ({
    searchContainer: {
        width: '100%',
        marginBottom: '10px',
    },
    success: {
        backgroundColor: '#5cb85c',
        "&:hover": {
            backgroundColor: '#4fa14f',
        }
    }
}));
function NumberFormatCustom(props) {
    const {inputRef, onChange, ...other} = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
        />
    );
}
