import 'date-fns';
import * as React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { Button, Grid } from "@material-ui/core";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import apiCall from "../../Api";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { subMonths, format } from "date-fns";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import GetAppIcon from '@material-ui/icons/GetApp';

const initRows = [];

export default function DataTable(props) {
  const [rows, setRows] = useState(initRows);
  const [users, setUsers] = useState(initRows);
  const [user, setUser] = useState(null);

  const columns = [
    {
      field: 'name', headerName: 'Name', width: 180,
      valueGetter: (params) => `${params.getValue('user').firstName} ${params.getValue('user').lastName}`
    },
    {
      field: 'username', headerName: 'Username', width: 130,
      valueGetter: (params) => params.getValue('user').username
    },
    {field: 'accountNo', headerName: 'Account No', width: 180,},
    {field: 'accountType', headerName: 'Account Type', width: 180,},
    {field: 'currency', headerName: 'Currency', width: 130,},
    {
      field: 'balance', headerName: 'Balance', width: 130,
      valueGetter: (params) => params.getValue('account').balance
    },
  ];
  props.setTitle('Customer Report')
  useEffect(() => {
    apiCall('/admins/accounts/accounts').then(x => setRows(x.response));
    apiCall('/admins/users').then(x => setUsers(x.response));
  }, []);
  const filteredRows = user && rows.filter(x => x.user.username === user.username) || rows;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>

      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Autocomplete
            options={users}
            getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
            onChange={(e, value) => setUser(value)}
            renderInput={(params) => <TextField {...params} label="User"/>}
          />
        </Grid>
        <Grid item xs={12}>
          <div style={{height: 400, width: '100%'}}>
            <DataGrid rows={filteredRows} id='tranKey' columns={columns} pageSize={5}/>
          </div>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
