import React, { useState } from "react";
import AppBar from "../appbar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Link } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  marginHeader: {
    marginTop: 20,
  },
  margin: {
    marginTop: 10,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: "#32266e;",
    color: "white",
    // height:"100px"
  },
  linkLogo: {
    textAlign: "center",
    fontSize: "25px",
  },
  link: {
    color: "white",
  },
  menu: {
    color: "white",
  },

  child: {
    marginTop: "3%",
  },
  slid: {
    height: "200px",
  },
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));
export default function Home(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);

  return (
    <div className={classes.root}>
    <AppBar />
    <Grid container className={classes.child}>
      <Grid item className={classes.child}>
        <Grid container justify="center">
          <Grid item className={classes.slid} xs={6}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="h6" className={classes.marginHeader}>
                  PRIVACY NOTICE
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.margin}>
                  Last updated: August, 1st, 2023{" "}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.margin}>
                  This privacy notice for Peace Treasury ("we," "us," or "our"),
                  describes how and why we might collect, store, use, and/or
                  share ("process") your information when you use our services
                  ("Services"), such as when you:
                </Typography>
              </Grid>

              <Grid item>
                <Typography className={classes.margin}>
                  Questions or concerns? Reading this privacy notice will help
                  you understand your privacy rights and choices. If you do not
                  agree with our policies and practices, please do not use our
                  Services. If you still have any questions or concerns, please
                  contact us at info@peacetreasury.com.{" "}
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="h6" className={classes.marginHeader}>
                  SUMMARY OF KEY POINTS
                </Typography>
              </Grid>

              <Grid item>
                <Typography className={classes.margin}>
                  This summary provides key points from our privacy notice, but
                  you can find out more details about any of these topics by
                  clicking the link following each key point or by using our{" "}
                  <a href="https://app.termly.io/user/login">
                    table of contents
                  </a>{" "}
                  below to find the section you are looking for.
                </Typography>
              </Grid>

              <Grid item>
                <Typography className={classes.margin}>
                  What personal information do we process? When you visit, use,
                  or navigate our Services, we may process personal information
                  depending on how you interact with us and the Services, the
                  choices you make, and the products and features you use. Learn
                  more about{" "}
                  <a href="https://app.termly.io/user/login">
                    personal information you disclose to us.
                  </a>
                </Typography>
              </Grid>

              <Grid item>
                <Typography className={classes.margin}>
                  Do we process any sensitive personal information? We do not
                  process sensitive personal information.
                </Typography>
              </Grid>

              <Grid item>
                <Typography className={classes.margin}>
                  Do we receive any information from third parties? We may
                  receive information from public databases, marketing partners,
                  social media platforms, and other outside sources. Learn more
                  about{" "}
                  <a href="https://app.termly.io/user/login">
                    information collected from other sources.
                  </a>{" "}
                </Typography>
              </Grid>

              <Grid item>
                <Typography className={classes.margin}>
                  Last updated: August, 1st, 2023{" "}
                </Typography>
              </Grid>

              <Grid item>
                <Typography className={classes.margin}>
                  Last updated: August, 1st, 2023{" "}
                </Typography>
              </Grid>

              <Grid item>
                <Typography color="primary" variant="body2">
                  1- Veterans Global Peace Network (www.vgpn.org)
                </Typography>
                <Typography color="primary" variant="body2">
                  2- International Peace Network
                </Typography>
              </Grid>

              <Grid item>
                <Typography className={classes.margin}>
                  Like all other peace organizations, Sarbaz Solh applies a
                  values-based approach to peacebuilding that emphasizes
                  universal principles and shared values as a basis to address
                  conflict, promote equitable and sustainable development, and
                  engage all members of the human family as necessary and
                  valuable contributors to building a culture of peace.{" "}
                </Typography>
              </Grid>

              <Grid item>
                <Typography className={classes.margin}>
                  We believe if the world is moving toward a much more
                  sustainable, just and equitable place to live in, we need to
                  get the right economic, social and environmental policies in
                  place and mobilize the financing required to bring those
                  policies to scale as soon as possible. The policies can lead
                  to the creation of smart financing strategies that put the
                  sustainable development goals progress into high gear at
                  global, regional, and country levels.{" "}
                </Typography>
              </Grid>

              <Grid item>
                <Typography className={classes.margin}>
                  That is why we have developed the most comprehensive financial
                  platform called Peace Treasury based on AI and Machine
                  Learning methodology to generate new income for the peace
                  individuals and organizations along with the fund for the
                  peace projects around the globe.{" "}
                </Typography>
              </Grid>

              <Grid item>
                <Typography className={classes.margin}>
                  A critically important element of our approach to generate
                  profit for all in Peace Treasury is our non-traditional asset
                  allocation. This asset allocation strategy involves
                  determining what share of the commodities and portfolio should
                  be invested in various broad categories of trades and
                  investments. This will guarantee the profit we promise to our
                  investors and the peace foundations.{" "}
                </Typography>
              </Grid>

              <Grid item>
                <Typography className={classes.margin}>
                  Peace Treasury is an innovative initiative to stimulate the
                  strategic investments required to get the world back on track
                  to make peace, safety and welfare for all. The purpose of
                  Peace Treasury is to provide steady support for the SDG’s
                  current and future operating needs, while preserving real
                  value for future generations. Within this new entity, we
                  provide a wide array of financial products and technical
                  assistance, and will help the peace organizations share and
                  apply innovative knowledge and solutions to the challenges
                  they face.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    </div>
  );
}
