import React from 'react'
import {createStyles, makeStyles} from '@material-ui/core/styles'

import List from '@material-ui/core/List'

import IconDashboard from '@material-ui/icons/Dashboard'
import IconPeople from '@material-ui/icons/People'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DomainIcon from '@material-ui/icons/Domain';
import TransferIcon from '@material-ui/icons/SyncAlt'
import logo from '../../assets/images/logo.jpg';

import AppMenuItem from './AppMenuItem'
import {Divider} from "@material-ui/core";
import {drawerWidth} from "../../App";


// type in menu is customer = 2 & admin = 3 & both = 1
const appMenuItems = [
  {
    name: 'Dashboard',
    link: '/',
    Icon: IconDashboard,
    type: 3
  }, 
  {
    name: 'Profile',
    Icon: AccountBoxIcon,
    items: [
      {name: 'My Profile', link: '/MyProfile', type: 1}, 
      {name: 'Change Password', link: '/ChangePassword', type: 1},
    ],
    type: 1
  },
  {
    name: 'Wallet',
    Icon: TransferIcon,
    items: [
      {name: 'My Wallet', link: '/Wallet', type: 1}, 
      {name: 'Deposite', link: '/deposite', type: 1},
      {name: 'Withdrawal', link: '/deposite', type: 1},
    ],
    type: 1
  },
  {
    name: 'Dashboard',
    link: '/dashboard',
    Icon: IconDashboard,
    type: 1
  }, 
  // {
  //   name: 'Deposite',
  //   link: '/deposite',
  //   Icon: TransferIcon,
  //   type: 1
  // },
 
    {
      name: 'Customer',
      Icon: IconPeople,
      items: [
        {name: 'Customers', link: '/customers', type: 3},
        // {name: 'Create Customer'},
        // {name: 'Edit Customer'},
        {name: 'Activate Customer', link: '/pending-customers', type: 3},
      ],
      type: 3
    },
  {
    name: 'Accounts',
    Icon: AccountBalanceIcon,
    items: [
      {name: 'Accounts', link: '/accounts', type: 1},
      {name: 'Cards', link: '/cards', type: 1},
      // {name: 'Create Accounts'},
      // {name: 'Edit Account'},
      {name: 'Activate Account', link: '/pending-accounts', type: 3},
    ],
    type: 1
  },
  {
    name: 'Transfer',
    Icon: TransferIcon,
    items: [
      {name: 'Between Accounts', link: '/transfer/accounts', type: 1},
      {name: 'Between Users', link: '/transfer/users', type: 1},
      {name: 'Inward Transfer', link: '/transfer/chargeRequest', type: 1},
      {name: 'Outward Transfer', link: '/transfer/outgoing', type: 1},
    ],
    type: 1
  },
  {
    name: 'Transaction',
    Icon: AttachMoneyIcon,
    items: [
      // {name: 'InterBank Transfer'},
      // {name: 'Internal Transfer'},
      {name: 'Pending Inward', link: '/pending-charges', type: 3},
      {name: 'Pending Outward', link: '/pending-transactions', type: 3},
    ],
    type: 3
  },
  {
    name: 'Reports',
    Icon: AssessmentIcon,
    items: [
      {name: 'Account Statement', link: '/statement', type: 1},
      {name: 'Customer List', link: '/customer-report', type: 3},
      {name: 'Revenue List', link: '/revenue-report', type: 3},
    ],
    type: 1
  },

  {
    name: 'Master Data',
    Icon: DomainIcon,
    items: [
      {name: 'User Groups', link: '/user-groups', type: 3},
      {name: 'Currencies', link: '/currencies', type: 3},
      {name: 'Fee Definitions', link: '/fee', type: 3},
      {name: 'Account Product Types', link: '/accountTypes', type: 3},
      {name: 'Card Types', link: '/cardTypes', type: 3},
      {name: 'Nostro Account'},
      {name: 'Calander'},

    ],
    type: 3
  },
]

const AppMenu = (props) => {
  const classes = useStyles()

  return (
    <List component="nav" className={classes.appMenu} disablePadding>
      <div className={classes.logoContainer}>
        <img className={classes.logo} src={logo} alt="Logo"/>
      </div>
      <Divider/>
      {/* <AppMenuItem {...appMenuItems[0]} /> */}
      {appMenuItems.map((item, index) => (
        <AppMenuItem {...item} key={index}/>
      ))}
      <AppMenuItem name='logout' Icon={ExitToAppIcon} click={props.logout} type={1}/>
    </List>
  )
}


const useStyles = makeStyles(theme =>
  createStyles({
    logoContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 30,
    },
    logo: {
      width: 50,
    },
    appMenu: {
      width: '100%',
    },
    navList: {
      width: drawerWidth,
    },
    menuItem: {
      width: drawerWidth,
    },
  }),
)

export default AppMenu
