import React, { useState } from "react";
import AppBar from "../appbar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Link } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  marginHeader: {
    marginTop: 20,
  },
  margin: {
    marginTop: 10,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: "#32266e;",
    color: "white",
    // height:"100px"
  },
  linkLogo: {
    textAlign: "center",
    fontSize: "25px",
  },
  link: {
    color: "white",
  },
  menu: {
    color: "white",
  },

  child: {
    marginTop: "6%",
  },
  slid: {
    height: "200px",
  },
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));
export default function Home(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);

  return (
    <div className={classes.root}>
    <AppBar />
    <Grid container className={classes.child}>
      <Grid item>
        <Grid container justify="center">
          <Grid item className={classes.slid} xs={6}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="h6" className={classes.marginHeader}>
                  Marketing Network
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.margin}>
                  In order to generate a huge population of peace promoters
                  around the globe and to make the world a better place to live
                  in, we need to initiate with active networking. Our peace
                  network includes everyone from friends and family, to work
                  colleagues, business connections, our social network, and
                  members of groups to which we belong. This network is about
                  forming trust and helping one another toward peace and
                  sustainable development goals. In addition to the purely good
                  feeling and the heart satisfaction for promoting the culture
                  of peace and humanity, the network members will be received
                  the financial income as the commission for growing the
                  network.
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.margin}>
                  This commission (0.5% of the total investment) that is
                  permanent and will be paid MONTHLY to Peace Promoters in the
                  network, is divided in 2 parts:
                </Typography>
              </Grid>
              <Grid item>
                <Typography color="primary" variant="body2">
                  - 0.3% for the Peace Promoter 1 (the person who has directly
                  invited the new member)
                </Typography>
                <Typography color="primary" variant="body2">
                  - 0.2% for the Peace Promoter 2 (the one who had invited Peace
                  Promoter 1 before)
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="h6" className={classes.marginHeader}>
                  NOTE:
                </Typography>
              </Grid>

              <Grid item>
                <Typography className={classes.margin}>
                  Every investor (Peace Promoter) will receive a unique referral
                  code to invite their friends and family members. So do Not
                  forget to ask your community to jot down your invitation code
                  while signing up on the platform.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    </div>
  );
}
