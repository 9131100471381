import * as React from 'react';
import {useEffect, useState} from 'react';
import {Grid} from "@material-ui/core";
import apiCall from "../Api";
import {makeStyles} from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import NumberFormat from "react-number-format";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

const initCustomer = [];
const initAccounts = [];
const getInit=()=> {return {fromUser: '',toUser:{id:null}, creditAcc: {}, debitAcc: {}, amount: '', memo: ''};}

export default function TransferBetweenUsers(props){

    const classes = useStyles();
    const history = useHistory();
    const user = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : null;
    const isAdmin = user === null;
    const [customers, setCustomers] = useState(initCustomer);
    const [accounts, setAccounts] = useState(initAccounts);
    const [transfer, setTransfer] = useState(getInit)
    const transferApi = () => {
        apiCall('/admins/transactions-internal', {
            "amount": transfer.amount,
            "fromAccountNo": transfer.debitAcc.accountNo,
            "memo": transfer.memo,
            "toAccountNo": transfer.creditAcc.accountNo
        }, 'POST').then(r => {
            if (r.status === 'SUCCESS') {
                alert("Transaction Success!");
                setTransfer({...getInit()})
            }
        })
    }

    useEffect(()=>{
        apiCall('/admins/customers/status/APPROVED').then(x => {
            setCustomers(x.response)
        });
        apiCall('/admins/accounts/accounts/status/APPROVED').then(r => setAccounts(r.response))
    },[])

    props.setTitle('Transfer Between Users')
    return(
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <span><b>Debit From</b></span>
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                className={classes.requiredField}
                                value={transfer.fromUser}
                                onChange={(e, p) => {
                                    transfer.fromUser = p;
                                    setTransfer({...transfer})
                                }}
                                options={isAdmin?customers.filter(c=>transfer.toUser&&transfer.toUser.id?c.id !== transfer.toUser.id:true):[user]}
                                classes={{
                                    option: classes.option,
                                }}
                                getOptionLabel={(option) => option.firstName ? `${option.firstName} ${option.lastName}` : option.username}
                                renderOption={(option) => (
                                    <React.Fragment>
                                        {option.firstName ? `${option.firstName} ${option.lastName}` : option.username}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        required
                                        {...params}
                                        label="User"
                                        variant="outlined"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                        helperText={transfer.fromUser && transfer.fromUser.id ? '' : "Debit User is Required."}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                className={classes.requiredField}
                                noOptionsText="Select Debit User."
                                value={transfer.debitAcc}
                                onChange={(e, p) => {
                                    transfer.debitAcc = p;
                                    setTransfer({...transfer})
                                }}
                                options={transfer.fromUser&&transfer.fromUser.id?accounts.filter(a=>a.user.username===transfer.fromUser.username):[]}
                                classes={{
                                    option: classes.option,
                                }}
                                autoHighlight
                                getOptionLabel={(option) => option.accountNo}
                                renderOption={(option) => (
                                    <React.Fragment>
                                        <ListItem alignItems="flex-start">
                                            <ListItemText
                                                primary={option.accountNo}
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography
                                                            component="span"
                                                            variant="body2"
                                                            color="textPrimary"
                                                        >
                                                            {option.accountType}
                                                        </Typography>
                                                        <br/>
                                                        {option.currency}
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        required
                                        {...params}
                                        label="Debit From"
                                        variant="outlined"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                        helperText={transfer.debitAcc && transfer.debitAcc.accountNo ? '' : "Debit Account is Required."}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <span><b>Credit To</b></span>
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                className={classes.requiredField}
                                value={transfer.toUser}
                                onChange={(e, p) => {
                                    transfer.toUser = p;
                                    setTransfer({...transfer})
                                }}
                                options={customers.filter(c=>transfer.fromUser&&transfer.fromUser.id?c.id!==transfer.fromUser.id:true)}
                                classes={{
                                    option: classes.option,
                                }}
                                autoHighlight
                                getOptionLabel={(option) => option.firstName ? `${option.firstName} ${option.lastName}` : option.username}
                                renderOption={(option) => (
                                    <React.Fragment>
                                        {option.firstName ? `${option.firstName} ${option.lastName}` : option.username}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="User"
                                        variant="outlined"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                        helperText={transfer.toUser && transfer.toUser.id ? '' : "Credit User is Required."}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                className={classes.requiredField}
                                noOptionsText="Select Credit User."
                                value={transfer.creditAcc}
                                onChange={(e, p) => {
                                    transfer.creditAcc = p;
                                    setTransfer({...transfer})
                                }}
                                options={transfer.toUser&&transfer.toUser.id?accounts.filter(a=>a.user.username===transfer.toUser.username):[]}
                                classes={{
                                    option: classes.option,
                                }}
                                autoHighlight
                                getOptionLabel={(option) => option.accountNo}
                                renderOption={(option) => (
                                    <React.Fragment>
                                        <ListItem alignItems="flex-start">
                                            <ListItemText
                                                primary={option.accountNo}
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography
                                                            component="span"
                                                            variant="body2"
                                                            color="textPrimary"
                                                        >
                                                            {option.accountType}
                                                        </Typography>
                                                        <br/>
                                                        {option.currency}
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        required
                                        {...params}
                                        label="Credit To"
                                        variant="outlined"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                        helperText={transfer.creditAcc && transfer.creditAcc.accountNo ? '' : "Credit Account is Required."}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        className={classes.requiredField}
                        label="Amount To Transfer"
                        value={transfer.amount}
                        style={{width: '100%'}}
                        onChange={(p) => {
                            transfer.amount = p.target.value;
                            setTransfer({...transfer})
                        }}
                        InputProps={{
                            inputComponent: NumberFormatCustom,
                        }}
                        helperText={transfer.amount ? '' : "Amount is Required."}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <span>Description</span>
                    <TextareaAutosize value={transfer.memo} style={{width: '100%', fontSize:'16px', fontFamily: "Roboto"}} rowsMin={10}
                                      onChange={(p) => {
                                          transfer.memo = p.target.value;
                                          setTransfer({...transfer})
                                      }}
                    />
                </Grid>
            </Grid>
            <hr/>
            <Button variant="contained" color="primary" onClick={() => transferApi()}
                    disabled={!(transfer.debitAcc.accountNo&&transfer.creditAcc.accountNo&&transfer.amount)}>
                Transfer
            </Button>
            &nbsp;
            <Button variant="outlined" onClick={() => history.goBack()}>
                Cancel
            </Button>
        </div>
    )
}

function NumberFormatCustom(props) {
    const {inputRef, onChange, ...other} = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
        />
    );
}
const useStyles = makeStyles((theme) => ({
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '100%',
    },
    requiredField: {
        "& .MuiFormHelperText-root": {
            color: 'red'
        }
    }
}));
