import React from 'react';
import PropTypes from 'prop-types';
import CoreTabs from '@material-ui/core/Tabs';
import CoreTab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import DebouncedMount from '../debounced-mount';

const useStyles = makeStyles(theme => ({
  root: {
    borderBottom: [[1, 'solid', theme.palette.divider]]
  },
  toolbar: {
    marginTop: theme.spacing(-6),
    float: 'right'
  }
}));

//TODO: ship to components
function Tab({ variant, value, onChange, children, items, toolbar, id }) {
  const classes = useStyles();
  function handleChange(event, value) {
    onChange(value);
  }

  function Children() {
    return children;
  }

  return (
    <div>
      <CoreTabs
        classes={{
          root: classes.root
        }}
        indicatorColor="primary"
        variant={variant}
        value={value}
        onChange={handleChange}
        id={id}
      >
        {items.map(item => (
          <CoreTab key={item.label} label={item.label} />
        ))}
      </CoreTabs>
      {toolbar && <div className={classes.toolbar}>{toolbar && toolbar}</div>}
      <DebouncedMount component={Children} />
      {/* <div>{children}</div> */}
    </div>
  );
}

Tab.propTypes = {
  variant: PropTypes.oneOf(['standard', 'scrollable', 'fullWidth']),
  value: PropTypes.number,
  onChange: PropTypes.func,
  children: PropTypes.any,
  items: PropTypes.array,
  toolbar: PropTypes.any,
  id: PropTypes.string
};

Tab.defaultProps = {
  variant: 'standard'
};

export default Tab;
