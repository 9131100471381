import * as React from "react";
import { useEffect, useState } from "react";
import apiCall from "../Api";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useTab from "use/useTab";
import Tab from "microcomponents/tab";

import { countryList } from "enum/list";

export default function MyProfile(props) {
  // props.setTitle('Change Password')
  const classes = styles();
  const history = useHistory();
  const user = sessionStorage.getItem("user")
    ? JSON.parse(sessionStorage.getItem("user"))
    : undefined;

  const [address, setAddress] = useState(user.residentialAddress);
  const [email, setEmail] = useState(user.email);
  const [mobileNo, setMobileNo] = useState(user.mobile);
  const [name, setName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [country, setCountry] = useState(user.countryOfResidence);
  function handleClick(e, items) { 
    setCountry(items?.code);
  }
  const handelClickList = (event) => { 
    setCountry(event.target.value);
  };
  useEffect(() => {
    // setAddress(user.residentialAddress);y
    // setEmail(user.email);
    // setMobileNo(user.firstName);
    // setName(user.firstName);
    // setLastName(user.lastName); 

    setCountry(user.countryOfResidence);
  }, []);
  const [ps, setPs] = useState({ pass: "", repass: "" });
 
  const changePassword = () => {
    let url = "/admins/customers/" + user.id;
    apiCall(url, { password: ps.pass }, "PUT").then((r) => {
      alert("password changed successfully");
      setPs({ ...{ pass: "", repass: "" } });
    });
  };

  const chageProfile = (e) => {
 
    if(!email||!mobileNo||!name||!lastName||!address||!country){
     alert("Please Enter your information")
     return;
    }

   else{
  
    const model={ 
      residentialAddress:address,  
      mobile:mobileNo,
      firstName:name,
      lastName:lastName,
      countryOfResidence:country,
      email:email,
      
      
    }
    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(model)
    };
    fetch(window.API_HOST + '/customers/change_profile', requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.status === 'FAILURE' || data.error) {
          alert('Failure change Profile');
        } else { 
          sessionStorage.removeItem('user');
          sessionStorage.setItem('user', JSON.stringify(data.response.user));

          alert('password changed successfully');
          history.push('/' )

        }
      });
   }
 
  }

  return (
    <div style={{ marginTop: "2%" }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <span>My Peace Code: {user?.registeredAddress}</span>
        </Grid>

        <Grid item xs={3}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="name"
            name="name"
            autoComplete="name"
            autoFocus
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="lastName"
            label="LastName"
            name="lastName"
            autoComplete="lastName"
            autoFocus
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="mobileNo"
            label="Mobile Number"
            name="mobileNo"
            autoComplete="mobileNo"
            autoFocus
            value={mobileNo}
            onChange={(e) => setMobileNo(e.target.value)}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="email"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </Grid> 
        <Grid item xs={3}>
          <Autocomplete
            label={"Country"}
            options={countryList}
            getOptionLabel={(option) => option.name}
            style={{ width: "100%" }}
            onChange={(event, code) => handleClick(code && event, code)} 
            defaultValue={{ ...countryList.find(x=>x.code===user.countryOfResidence) }}
            renderInput={(params) => (
              <TextField
                {...params}
                onChange={(event) => handelClickList(event)}
                label="Country"
                variant="outlined" 
               />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="address"
            label="address"
            id="address"
            autoComplete="address"
            multiline
            rows={1}
            onChange={(e) => setAddress(e.target.value)}
            value={address}
          />
        </Grid>
      </Grid>
      <hr />
      <Button
        variant="contained"
        color="primary"
        onClick={() => chageProfile()}
      
      >
       Submit
      </Button>
      &nbsp;
      {/* <Button variant="outlined" onClick={() => history.goBack()}>
        Cancel
      </Button> */}
    </div>
  );
}

const styles = makeStyles((theme) => ({
  searchContainer: {
    width: "100%",
    marginBottom: "10px",
  },
  success: {
    backgroundColor: "#5cb85c",
    "&:hover": {
      backgroundColor: "#4fa14f",
    },
  },
  requiredField: {
    "& .MuiFormHelperText-root": {
      color: "red",
    },
  },
}));
