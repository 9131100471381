import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import clsx from "clsx";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./overrides.css"; // change this to the file path of your overrides
import { Link } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    // backgroundColor: "#32266e;",
    color: "white",
    minHeight:"42px",
  },
  appBar: {
    backgroundColor: "#4d72dd;",
 
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: "white",
  },
  link: {
    color: "white",
    fontStyle: "italic",
    fontWeight: "bolder",
  },
  linkLogo: {
    color: "white",
    textAlign: "center",
    fontSize: "15px",
    fontStyle: "italic",
    fontWeight: "bolder",
  },
  menu: {
    color: "white",
  },

  child: {
    marginTop: "3%",
  },
  slid: {
    height: "200px",
  },
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  typo: {
    fontStyle: "italic",
    fontWeight: "bolder",
  },
}));
export default function Appbar(props) {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={clsx(classes.appBar)}>
      <Toolbar className={classes.toolbar}>
        <Grid container justify="center">
          <Grid xs={11}>
            <Grid
              spacing={4}
              container
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <Link
                  href="/"
                  // style={{ color: "#f0c829" }}
                  className={classes.linkLogo}
                >
                  {"Peace Treasury "}
                </Link>
              </Grid>

              <Grid item>
                <Link href="/login" variant="body2" className={classes.link}>
                  {"Sign In / Register"}
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
