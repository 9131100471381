import {Grid} from "@material-ui/core";
import React from "react";
import TextField from "@material-ui/core/TextField";
import NumberFormat from "react-number-format";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {makeStyles} from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

export default function OccupationInfo(params) {
    const {user} = params;
    const setItem = params.setItem;
    const classes = styles();

    return (
        <Grid container spacing={3}>

            <Grid item xs={12} md={4} lg={4}>
                <TextField
                    label='Company Name'
                    value={user.companyName}
                    onChange={(p) => setItem('companyName', p.target.value)}/></Grid>
            <Grid item xs={12} md={4} lg={4}>
                <TextField
                    label='Monthly Income'
                    value={user.monthlyIncome}
                    InputProps={{
                        inputComponent: NumberFormatCustom,
                    }}
                    onChange={(p) => setItem('monthlyIncome', p.target.value)}/></Grid>
            <Grid item xs={12} md={4} lg={4}>
                <TextField
                    label='Source Of Income'
                    value={user.sourceOfIncome}
                    onChange={(p) => setItem('sourceOfIncome', p.target.value)}/></Grid>
            <Grid item xs={12} md={4} lg={4}>
                <TextField
                    label='Occupation'
                    value={user.occupation}
                    onChange={(p) => setItem('occupation', p.target.value)}/></Grid>
            <Grid item xs={12} md={4} lg={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="homeOwnerShip">Home Ownership Status</InputLabel>
                    <Select label="Home Ownership Status" value={user.homeOwnershipStatus}
                            style={{width: 300}}
                            onChange={(p) => setItem('homeOwnershipStatus', p.target.value)}
                            inputProps={{
                                name: 'Home Ownership Status',
                                id: 'homeOwnerShip',
                            }}>
                        <MenuItem/>
                        <MenuItem value={'Yes'}>Yes</MenuItem>
                        <MenuItem value={'NO'}>No</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
                <TextField
                    label='Office Phone'
                    value={user.officePhone}
                    onChange={(p) => setItem('officePhone', p.target.value)}/></Grid>
            <Grid item xs={12}>
                <InputLabel htmlFor="officeAddress">Office Address</InputLabel>
                <TextareaAutosize
                    id='officeAddress'
                    style={{width: '90%', fontSize:'16px', fontFamily: "Roboto"}}
                    rowsMax={4}
                    rowsMin={3}
                    value={user.officeAddress}
                    onChange={(p) => setItem('officeAddress', p.target.value)}
                />
            </Grid>
        </Grid>
    )
}


function NumberFormatCustom(props) {
    const {inputRef, onChange, ...other} = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
        />
    );
}

const styles = makeStyles((theme) => ({
    searchContainer: {
        width: '100%',
        marginBottom: '10px',
    },
    success: {
        backgroundColor: '#5cb85c',
        "&:hover": {
            backgroundColor: '#4fa14f',
        }
    }
}));
