import * as React from "react";
import { useEffect, useState } from "react";
import apiCall from "../Api";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useTab from "use/useTab";
import Tab from "microcomponents/tab";

import { countryList } from "enum/list";

export default function MyProfile(props) {
  // props.setTitle('Change Password')
  const classes = styles();
  const history = useHistory();
  const user = sessionStorage.getItem("user")
    ? JSON.parse(sessionStorage.getItem("user"))
    : undefined;

  const [ps, setPs] = useState({ pass: "", repass: "" });

  return (
    <div style={{ marginTop: "6%" }}>
      <Grid container justify="center">
        <Grid item xs={5}  className={classes.root}>
          <Grid container justify="center">
            <Grid item xs={11}>
              <Grid container direction="column" spacing={3}>
              <Grid item />
                <Grid item>
                  <Grid container justify="space-between">
                    <Grid item>
                      <Typography>My Investment :</Typography>
                    </Grid>
                    <Grid item>
                      <Typography> ….. USD</Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container justify="space-between">
                    <Grid item>
                      <Typography> Inv. Profit :</Typography>
                    </Grid>
                    <Grid item>
                      <Typography> ….. USD</Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container justify="space-between">
                    <Grid item>
                      <Typography>My Network Commission :</Typography>
                    </Grid>
                    <Grid item>
                      <Typography> ….. USD</Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container justify="space-between">
                    <Grid item>
                      <Typography> Total (Com+Profits) :</Typography>
                    </Grid>
                    <Grid item>
                      <Typography> ….. USD</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const styles = makeStyles((theme) => ({
  searchContainer: {
    width: "100%",
    marginBottom: "10px",
  },
  success: {
    backgroundColor: "#5cb85c",
    "&:hover": {
      backgroundColor: "#4fa14f",
    },
  },
  requiredField: {
    "& .MuiFormHelperText-root": {
      color: "red",
    },
  },
  root: {
    border: [[1, "solid", "black"]],
    borderRadius:5
  },
}));
