import * as React from 'react';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {useHistory} from "react-router";
import Title from "../dashboard/Title";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import {SvgIcon} from "@material-ui/core";


export default function Transfer(props) {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const [stats, setStats] = React.useState({user: {}, acct: {}, tran: {}, charge: {}});
    props.setTitle('Transfer');
    return (
        <div>
            <Grid container xs={12} spacing={3}>
                <Grid item xs={12} md={4} lg={4}>
                    <Paper className={fixedHeightPaper}>
                        <DashboardCart title="Transfer Between Accounts" Icon={TransferBetweenAccountsIcon}
                                       value={stats.user.cnt} date={stats.user.maxDate}
                                       link='/transfer/accounts'/>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <Paper className={fixedHeightPaper}>
                        <DashboardCart title="Transfer Between Users" Icon={TransferBetweenBanksIcon}
                                       value={stats.user.cnt} date={stats.user.maxDate}
                                       link='/transfer/users'/>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <Paper className={fixedHeightPaper}>
                        <DashboardCart title="Outgoing Wire Transfer" Icon={OutgoingTransfer} value={stats.user.cnt}
                                       date={stats.user.maxDate}
                                       link='/transfer/outgoing'/>
                    </Paper>
                </Grid>
            </Grid>

        </div>
    )
}

function DashboardCart(props) {
    const classes = useStyles();
    const history = useHistory();
    const {Icon} = props;
    return (
        <React.Fragment>
            <Link color="primary" href='#' onClick={() => history.push(props.link)} className={classes.context}>
                {!!Icon && (<Icon style={{fontSize: 80}} color='primary'/>)}
                <Title>{props.title}</Title>
                <Typography component="p" variant="h4">
                    {props.value}
                </Typography>
                <Typography color="textSecondary" className={classes.depositContext}>
                    {props.date && `on ${props.date}`}
                </Typography>
            </Link>
        </React.Fragment>
    );
}

function TransferBetweenAccountsIcon(props) {
    return (
        <SvgIcon height="36" viewBox="0 0 99 36" width="99" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fill-rule="evenodd" stroke="#469DB8" stroke-width="2">
                <path d="M43 29h8v5.304L61 25 51 13v6h-3v4L38 13 48 1v6h8" stroke-linecap="round"
                      stroke-linejoin="round"></path>
                <g transform="translate(0 3)">
                    <rect height="18" rx="4" width="24" x="1" y="1"></rect>
                    <path d="M1 6h23M1 14h8M1 10h18M13 14h3" stroke-linecap="round" stroke-linejoin="round"></path>
                </g>
                <g transform="translate(73 15)">
                    <rect height="18" rx="4" width="24" x="1" y="1"></rect>
                    <path d="M1 6h23M1 14h8M1 10h18M13 14h3" stroke-linecap="round" stroke-linejoin="round"></path>
                </g>
            </g>
        </SvgIcon>
    )
}

function TransferBetweenBanksIcon(props) {
    return (
        <SvgIcon height="37" viewBox="0 0 87 37" width="87"  {...props}>
            <g fill="none" fill-rule="evenodd" stroke="#469DB8" stroke-linecap="round"
               stroke-linejoin="round" stroke-width="2">
                <path d="M37 29h8v5.304L55 25 45 13v6h-3v4L32 13 42 1v6h8"></path>
                <g transform="translate(1 2)">
                    <circle cx="9.5" cy="4.5" r="4.5"></circle>
                    <path
                        d="M5.938 22H0v-3.957a6 6 0 0 1 1.285-3.71l.67-.85a4 4 0 0 1 4.128-1.403l1.94.493a6 6 0 0 0 2.955 0l1.94-.493a4 4 0 0 1 4.128 1.402l.669.85A6 6 0 0 1 19 18.043V22h-5.938v-3.017"></path>
                </g>
                <g transform="translate(67 14)">
                    <circle cx="9.5" cy="4.5" r="4.5"></circle>
                    <path
                        d="M13.063 22H19v-3.957a6 6 0 0 0-1.285-3.71l-.67-.85a4 4 0 0 0-4.128-1.403l-1.94.493a6 6 0 0 1-2.955 0l-1.94-.493a4 4 0 0 0-4.128 1.402l-.669.85A6 6 0 0 0 0 18.043V22h5.938v-3.017"></path>
                </g>
            </g>
        </SvgIcon>
    )
}

function OutgoingTransfer(props) {
    return (
        <SvgIcon height="28" viewBox="0 0 96 28" width="96" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fill-rule="evenodd">
                <path d="M39 19h8v5.304L57 15 47 3v6h-8" stroke="#469DB8" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="2"></path>
                <g stroke="#469DB8" stroke-width="2">
                    <path
                        d="M82 1c7.18 0 13 5.82 13 13s-5.82 13-13 13-13-5.82-13-13c.008-7.176 5.824-12.992 13-13z"
                        fill-rule="nonzero"></path>
                    <g stroke-linecap="round" stroke-linejoin="round">
                        <path
                            d="M78 18a2 2 0 0 0 2 2h4a3 3 0 1 0 0-6h-4a3 3 0 1 1 0-6h4a2 2 0 0 1 2 2M82 6v1M82 21v1"></path>
                    </g>
                </g>
                <g transform="translate(1 1)">
                    <path
                        d="M13 0L.908 5.58C.354 5.837 0 6.39 0 7a1 1 0 0 0 1 1h24a1 1 0 0 0 1-1c0-.61-.354-1.164-.908-1.42L13 0z"
                        stroke="#469DB8" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
                    <rect height="4" rx="2" stroke="#469DB8" stroke-width="2" width="24" x="1"
                          y="22"></rect>
                    <circle cx="13" cy="4" fill="#469DB8" r="1"></circle>
                    <rect height="6" rx="2" stroke="#469DB8" stroke-width="2" width="4" x="1"
                          y="12"></rect>
                    <rect height="6" rx="2" stroke="#469DB8" stroke-width="2" width="4" x="11"
                          y="12"></rect>
                    <rect height="6" rx="2" stroke="#469DB8" stroke-width="2" width="4" x="21"
                          y="12"></rect>
                </g>
            </g>
        </SvgIcon>
    )
}

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'none',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 150,
        borderColor: '#94C817',
        borderWidth: 2,
        borderStyle: 'solid'
    },
    context: {
        textAlign: 'center',
        '& svg': {
            height: 72,
            width: 150,
            stroke: '#469DB8'
        },
    }
}));
