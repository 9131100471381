import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {useHistory} from "react-router";
import PersonalInfo from "./PersonalInfo";
import Paper from "@material-ui/core/Paper";
import OccupationInfo from "./OccupationInfo";
import ContactInfo from "./ContactInfo";
import apiCall from "../../Api";
import TextField from "@material-ui/core/TextField";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import {format} from "date-fns";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
    },
    comments: {
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
    },
}));

function getSteps() {
    return ['Personal Details', 'Occupation Information', 'Contact Information'];
}

const emptyUser = {
    "title": "",
    "firstName": "",
    "middleName": "",
    "lastName": "",
    "nationalIdNo": "",
    "dateOfBirth": new Date(),
    "placeOfBirth": "",
    "idCardImage": "",
    "profileImage": "",

    "passportImage": "",
    "nationality": "",
    "signImage": "",
    "passportNo": "",
    "passportIssueDate": new Date(),
    "passportIssuePlace": "",

    "educationLevel": "",
    "maritalStatus": "",
    "noOfChildren": "",

    "companyName": "",
    "monthlyIncome": null,
    "sourceOfIncome": "",
    "occupation": "",
    "officeAddress": "",
    "officePhone": "",


    "username": "",
    "mobile": "",
    "mobileVerified": null,
    "email": "",
    "emailVerified": null,
    "countryOfResidence": "",
    "registeredAddress": "",
    "residencePhone": "",
    "residentialAddress": "",
    "homeOwnershipStatus": "",
    "permanentAddress": "",
    "correspondenceAddress": "",
    "addressProofImage": "",
    "userGroup": {id: null}
}

function getStepContent(stepIndex, user) {
    switch (stepIndex) {
        case 0:
            return <PersonalInfo user={user}/>;
        case 1:
            return <OccupationInfo user={user}/>;
        case 2:
            return <ContactInfo user={user}/>;
        default:
            return 'Unknown stepIndex';
    }
}

export default function CustomerDetails(params) {
    const {editable} = params;
    const classes = useStyles();
    const history = useHistory();
    const [user, setUser] = React.useState(emptyUser);
    const [comments, setComments] = useState('');
    const [activeStep, setActiveStep] = React.useState(0);
    const checkRequired = (level) => {
        return !(level < 2 ? user.firstName && user.lastName && user.nationalIdNo && user.passportNo : user.firstName && user.lastName && user.nationalIdNo && user.passportNo && user.username && user.mobile && user.email && user.residentialAddress && user.userGroup && user.userGroup.id)

    }
    const {id} = params.match.params;
    const steps = getSteps();
    useEffect(() => {
        if (id !== '0')
            apiCall('/admins/customers/' + id).then(x => {
                let res = x.response;
                res.password = '';
                res.dateOfBirth = new Date(res.dateOfBirth ? res.dateOfBirth : '1980-01-01');
                res.passportIssueDate = res.passportIssueDate ? new Date(res.passportIssueDate) : new Date();
                res.profileImage = res.profileImage ? `${window.API_HOST}/customers/image/${res.profileImage}` : undefined
                res.idCardImage = res.idCardImage ? `${window.API_HOST}/customers/image/${res.idCardImage}` : undefined
                res.passportImage = res.passportImage ? `${window.API_HOST}/customers/image/${res.passportImage}` : undefined
                res.signImage = res.signImage ? `${window.API_HOST}/customers/image/${res.signImage}` : undefined
                res.addressProofImage = res.addressProofImage ? `${window.API_HOST}/customers/image/${res.addressProofImage}` : undefined
                setUser(res)
                setComments(x.response && x.response.adminComment)
            });
    }, [id]);
    const getDTO = () => {
        const startUrl = `${window.API_HOST}/customers/image/`;
        const dto = {...user};
        if(!user.password)
            dto.password = null
        dto.dateOfBirth = dto.dateOfBirth ? format(dto.dateOfBirth, 'yyyy-MM-dd') : undefined;
        dto.passportIssueDate = dto.passportIssueDate ? format(dto.passportIssueDate, 'yyyy-MM-dd') : undefined;
        dto.idCardImage = dto.idCardImage && !dto.idCardImage.startsWith(startUrl) ? dto.idCardImage.split(';base64,')[1] : undefined;
        dto.profileImage = dto.profileImage && !dto.profileImage.startsWith(startUrl) ? dto.profileImage.split(';base64,')[1] : undefined;
        dto.passportImage = dto.passportImage && !dto.passportImage.startsWith(startUrl) ? dto.passportImage.split(';base64,')[1] : undefined;
        dto.signImage = dto.signImage && !dto.signImage.startsWith(startUrl) ? dto.signImage.split(';base64,')[1] : undefined;
        dto.addressProofImage = dto.addressProofImage && !dto.addressProofImage.startsWith(startUrl) ? dto.addressProofImage.split(';base64,')[1] : undefined;
        return dto;
    }
    const updateCustomer = () => {
        const dto = getDTO();

        let url = '/admins/customers';
        if (id !== '0')
            url += '/' + id;
        apiCall(url, dto, id !== '0' ? 'PUT' : 'POST').then(r => {
            history.goBack()
        });
    }
    const handleFinish = (status) => {
        let dto = getDTO();
        dto.adminComment = comments;
        dto.status = status
        apiCall('/admins/customers/' + id, {...dto}, 'PUT').then(resp => history.push('/pending-customers'));
    };

    const setItem = (code, val) => {
        user[code] = val;
        setUser({...user});
    }

    const handleNext = () => {

        if (activeStep === steps.length - 1) {
            return history.goBack();
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        if (activeStep == 0)
            history.goBack();
        else
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                {activeStep === steps.length ? (
                    <div>
                        <Typography className={classes.instructions}>All steps completed</Typography>
                        <Button onClick={handleReset}>Reset</Button>
                    </div>
                ) : (
                    <div>
                        <Paper className={classes.instructions}>
                            {activeStep === 0 &&
                            <PersonalInfo {...user} setItem={setItem.bind(this)}/>
                            }
                            {activeStep === 1 &&
                            <OccupationInfo user={user} setItem={setItem.bind(this)}/>
                            }
                            {activeStep === 2 &&
                            <ContactInfo user={user} setItem={setItem.bind(this)}/>
                            }
                        </Paper>
                        {editable && <div className={classes.comments}>
                            <TextField label='Admin Comments' value={comments} fullWidth multiline rows={4}
                                       onChange={(e) => setComments(e.target.value)}/>
                        </div>
                        }
                        <div>
                            <Button
                                // disabled={activeStep === 0}
                                onClick={handleBack}
                                className={classes.button}
                            >
                                Back
                            </Button>
                            {activeStep < steps.length - 1 && (
                                <Button className={classes.button} variant="contained" color="primary"
                                        onClick={handleNext} disabled={checkRequired(activeStep)}>Next</Button>)}
                            {editable && activeStep === steps.length - 1 && (
                                <Button className={classes.button} variant="contained" color="primary"
                                        onClick={() => handleFinish('APPROVED')}
                                        disabled={checkRequired(activeStep)}><CheckIcon/> Approve</Button>)}
                            {editable && activeStep === steps.length - 1 && (
                                <Button className={classes.button} variant="contained" color="secondary"
                                        onClick={() => handleFinish('REJECTED')}
                                        disabled={checkRequired(activeStep)}><ClearIcon/> Reject</Button>)}
                            {!editable && activeStep === steps.length - 1 && (
                                <Button className={classes.button} variant="contained" color="primary"
                                        onClick={() => updateCustomer()}
                                        disabled={checkRequired(activeStep)}>Done</Button>)}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
