import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  vg1: {
    height: theme.spacing(1)
  },
  vg2: {
    height: theme.spacing(2)
  },
  vg3: {
    height: theme.spacing(3)
  },
  vg4: {
    height: theme.spacing(4)
  },
  vg5: {
    height: theme.spacing(5)
  },
  vg6: {
    height: theme.spacing(6)
  },
  vg7: {
    height: theme.spacing(7)
  },
  vg8: {
    height: theme.spacing(8)
  }
}));

function VerticalGap({ height }) {
  const classes = useStyles();
  return <div className={classes[`vg${height}`]} />;
}

VerticalGap.propTypes = {
  height: PropTypes.number
};

VerticalGap.defaultProps = {};

export default VerticalGap;
