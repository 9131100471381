import * as React from "react";
import {useEffect, useState} from "react";
import {Grid} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import apiCall from "../../Api";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router";

const initCardType = {
    title: '',
    category: '',
    code: '',
    format: ''
};

export default function EditCardType(props) {
    props.setTitle('Edit Card Tye')
    const {id} = props.match.params;
    const history = useHistory();
    const classes = useStyles();
    const [cardType, setCardType] = useState(initCardType);
    const [currencies, setCurrencies] = useState([]);
    const setItem = (code, val) => {
        cardType[code] = val;
        setCardType({...cardType})
    }
    const save=()=>{
        let url = '/admins/card-types';
        let method = 'POST';
        if(id!=='0'){
            url+='/'+id;
            method = 'PUT'
        }
        apiCall(url,{...cardType},method).then(r=> history.goBack())
    }

    useEffect(() => {
        apiCall('/admins/currencies',).then(r => setCurrencies(r.response))
        if(id!=='0'){
            apiCall('/admins/card-types').then(r=>{
                setCardType({...r.response.filter(a=> a.id.toString()===id)[0]})
            })
        }
    }, [])

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required fullWidth
                        className={classes.requiredField}
                        label="Title"
                        variant="outlined"
                        value={cardType.title}
                        onChange={(p) => setItem('title', p.target.value)}
                        helperText={cardType.title ? '' : 'Title is Required.'}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required fullWidth
                        className={classes.requiredField}
                        label="Code"
                        variant="outlined"
                        value={cardType.code}
                        onChange={(p) => setItem('code', p.target.value)}
                        helperText={cardType.code ? '' : 'Code is Required.'}
                    />
                </Grid>
                {/*<Grid item xs={12} sm={6}>*/}
                {/*    <TextField*/}
                {/*        required fullWidth*/}
                {/*        className={classes.requiredField}*/}
                {/*        label="Format"*/}
                {/*        variant="outlined"*/}
                {/*        value={cardType.format}*/}
                {/*        onChange={(p) => setItem('format', p.target.value)}*/}
                {/*        helperText={cardType.format ? '' : 'Format is Required.'}*/}
                {/*    />*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12} sm={6}>*/}
                {/*    <TextField*/}
                {/*        required fullWidth*/}
                {/*        className={classes.requiredField}*/}
                {/*        label="Category"*/}
                {/*        variant="outlined"*/}
                {/*        value={cardType.category}*/}
                {/*        onChange={(p) => setItem('category', p.target.value)}*/}
                {/*        helperText={cardType.category? '' : 'Category is Required.'}*/}
                {/*    />*/}
                {/*</Grid>*/}
            </Grid>
            <hr/>
            <Button variant="contained" color="primary" onClick={() => save()}
                    disabled={!(cardType.title&&cardType.code)}>
                Save Changes
            </Button>
            &nbsp;
            <Button variant="outlined" onClick={() => history.goBack()}>
                Cancel
            </Button>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: '100%'
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    requiredField: {
        "& .MuiFormHelperText-root": {
            color: 'red'
        }
    }
}));
