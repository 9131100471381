import * as React from 'react';
import {useEffect, useState} from 'react';
import {useHistory} from "react-router";
import {Button, useMediaQuery} from "@material-ui/core";
import AddCircle from "@material-ui/icons/AddCircle";
import {DataGrid} from "@material-ui/data-grid";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import apiCall from "../../Api";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";


const initRows = [];

export default function CardList(props) {
    const classes = styles();
    const history = useHistory();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [rows, setRows] = useState(initRows);
    const user = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : null;
    const isAdmin = user === null;
    const refresh = () => apiCall('/admins/cards').then(x => {
        let res = x.response
        if (!isAdmin) {
            res = res.filter(c => c.user.username === user.username);
        }
        setRows(res)
    });
    const columns = [
        {field: 'id', headerName: 'ID', width: 0},
        {field: 'createDate', headerName: 'Create Date'},
        {
            field: 'user.username',
            headerName: 'Owner',
            width: 130,
            renderCell: (params) => (`${params.getValue('user').firstName} ${params.getValue('user').lastName}`)
        },
        {field: 'cardNo', headerName: 'Card #', width: 160},
        {
            field: 'account.accountNo',
            headerName: 'Account #',
            width: 160,
            renderCell: (params) => (params.getValue('account').accountNo)
        },
        {
            field: 'account.currency',
            headerName: 'Currency',
            renderCell: (params) => (params.getValue('account').currency)
        },
        {
            field: 'cardType.title',
            headerName: 'Type',
            width: 100,
            renderCell: (params) => (params.getValue('cardType').title)
        },
        {
            field: 'expMonth',
            headerName: 'Expiration Date',
            width: 130,
            renderCell: (params) => (`${params.getValue('expYear')}/${params.getValue('expMonth')}`)
        },
        {field: 'status', headerName: 'Status', sortable: false,},
        {
            field: 'actions',
            headerName: 'Actions',
            width: 300,
            renderCell: (params) => isAdmin ? (
                <div>
                    <Button color="primary"
                            onClick={() => {
                                history.push('/cards/' + params.getValue('id'))
                            }}><EditIcon/></Button>
                    <Button color="secondary"
                            onClick={() => {
                                deleteFeeConfirm(params.getValue('id'))
                            }}><DeleteIcon/></Button>
                </div>
            ) : (<div>&nbsp;</div>)
        }
    ];
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [feeId, setFeeId] = useState(null);
    const deleteFeeConfirm = (id) => {
        setFeeId(id)
        setOpenConfirm(true)
    }
    const deleteItem = (id) => {
        setOpenConfirm(false)
        apiCall('/admins/cards/' + feeId, {}, 'DELETE').then(r => {
            alert('Delete Successfully');
            refresh();
        })
    }
    const handleCloseConfirm = () => {
        setOpenConfirm(false)
    }
    props.setTitle('Cards')
    useEffect(() => {
        refresh()
    }, []);
    return (
        <div style={{height: 400, width: '100%'}}>
            {isAdmin &&
            <div className={classes.searchContainer}>
                <Button
                    className={classes.success}
                    variant="contained"
                    color="primary"
                    startIcon={<AddCircle/>}
                    onClick={() => history.push('/cards/0')}>Create Card</Button>
            </div>
            }
            <DataGrid rows={rows} columns={columns} pageSize={5}/>
            <Dialog fullScreen={fullScreen} open={openConfirm} onClose={handleCloseConfirm}
                    aria-labelledby="responsive-dialog-title">
                <DialogTitle id="responsive-dialog-title">{"Delete User Group?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={deleteItem} variant="contained" color="primary">
                        Yes
                    </Button>
                    <Button onClick={handleCloseConfirm} color="primary" autoFocus>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const styles = makeStyles((theme) => ({
    searchContainer: {
        width: '100%',
        marginBottom: '10px',
    },
    success: {
        backgroundColor: '#5cb85c',
        "&:hover": {
            backgroundColor: '#4fa14f',
        }
    }
}));
