import * as React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { Button } from "@material-ui/core";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import apiCall from "../../Api";


const initRows = [];

export default function DataTable(props) {
  const [rows, setRows] = useState(initRows);
  const history = useHistory();
  const columns = [
    {field: 'id', headerName: 'ID', width: 70},
    {field: 'firstName', headerName: 'First name', width: 130},
    {field: 'lastName', headerName: 'Last name', width: 130},
    {
      field: 'age',
      headerName: 'Age',
      type: 'number',
      width: 90,
      valueGetter: (params) =>
        parseInt((new Date() - new Date(params.getValue('dateOfBirth') || '')) / 1000 / 60 / 60 / 30 / 365),
    },
    {
      field: 'fullName',
      headerName: 'Full name',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 360,
      valueGetter: (params) =>
        `${params.getValue('firstName') || ''} ${params.getValue('lastName') || ''}`,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      renderCell: (params) => (
        <Button
          onClick={() => history.push('/pending-customers/' + params.getValue('id'))}><KeyboardArrowRightIcon/></Button>)
    },
  ];
  props.setTitle('Pending Customers')
  useEffect(() => {
    apiCall('/admins/customers/status/NEW').then(x => setRows(x.response));
  }, []);
  return (
    <div style={{height: 400, width: '100%'}}>
      <DataGrid rows={rows} columns={columns} pageSize={5}/>
    </div>
  );
}
