import React, { useState } from "react";
import AppBar from "../appbar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Link } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  marginHeader: {
    marginTop: 20,
  },
  margin: {
    marginTop: 10,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: "#32266e;",
    color: "white",
    // height:"100px"
  },
  linkLogo: {
    textAlign: "center",
    fontSize: "25px",
  },
  link: {
    color: "white",
  },
  menu: {
    color: "white",
  },

  child: {
    marginTop: "4%",
  },
  slid: {
    height: "200px",
  },
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));
export default function Home(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);

  return (
    <div className={classes.root}>
      <AppBar />
      <Grid container direction="column" spacing={3} className={classes.child}>
        <Grid item >
          <Grid container justify="center">
            <Grid item className={classes.slid} xs={6}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="h6" className={classes.marginHeader}>
                    ABOUT US
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.margin}>
                    Peace Treasury has been founded by Sarbaz Solh, an
                    international, non-governmental organization promoting the
                    culture of peace and supporting peace projects and campaigns
                    worldwide. Sarbaz Solh has co-founded 2 prominent and
                    efficient international networks:
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography color="primary" variant="body2">
                    1- Veterans Global Peace Network (www.vgpn.org)
                  </Typography>
                  <Typography color="primary" variant="body2">
                    2- International Peace Network
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography className={classes.margin}>
                    Like all other peace organizations, Sarbaz Solh applies a
                    values-based approach to peacebuilding that emphasizes
                    universal principles and shared values as a basis to address
                    conflict, promote equitable and sustainable development, and
                    engage all members of the human family as necessary and
                    valuable contributors to building a culture of peace.{" "}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography className={classes.margin}>
                    We believe if the world is moving toward a much more
                    sustainable, just and equitable place to live in, we need to
                    get the right economic, social and environmental policies in
                    place and mobilize the financing required to bring those
                    policies to scale as soon as possible. The policies can lead
                    to the creation of smart financing strategies that put the
                    sustainable development goals progress into high gear at
                    global, regional, and country levels.{" "}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography className={classes.margin}>
                    That is why we have developed the most comprehensive
                    financial platform called Peace Treasury based on AI and
                    Machine Learning methodology to generate new income for the
                    peace individuals and organizations along with the fund for
                    the peace projects around the globe.{" "}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography className={classes.margin}>
                    A critically important element of our approach to generate
                    profit for all in Peace Treasury is our non-traditional
                    asset allocation. This asset allocation strategy involves
                    determining what share of the commodities and portfolio
                    should be invested in various broad categories of trades and
                    investments. This will guarantee the profit we promise to
                    our investors and the peace foundations.{" "}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography className={classes.margin}>
                    Peace Treasury is an innovative initiative to stimulate the
                    strategic investments required to get the world back on
                    track to make peace, safety and welfare for all. The purpose
                    of Peace Treasury is to provide steady support for the SDG’s
                    current and future operating needs, while preserving real
                    value for future generations. Within this new entity, we
                    provide a wide array of financial products and technical
                    assistance, and will help the peace organizations share and
                    apply innovative knowledge and solutions to the challenges
                    they face.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
