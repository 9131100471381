import * as React from "react";
import {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {useMediaQuery} from "@material-ui/core";
import apiCall from "../../Api";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {AddCircle} from "@material-ui/icons";
import {DataGrid} from "@material-ui/data-grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

const initRows = [];

export default function CardTypeList(props){
    props.setTitle('Card Type List');
    const history = useHistory();
    const classes = styles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [rows, setRows] = useState(initRows);
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [feeId, setFeeId] = useState(null);
    const deleteFeeConfirm=(id)=>{
        setFeeId(id)
        setOpenConfirm(true)
    }
    const deleteItem=(id)=>{
        setOpenConfirm(false)
        apiCall('/admins/card-types/'+feeId,{},'DELETE').then(r=>{
            alert('Delete Successfully');
            refresh();
        })
    }
    const handleCloseConfirm = () => {
        setOpenConfirm(false)
    }
    const refresh = () => apiCall('/admins/card-types').then(x => x.response && x.response.length && setRows(x.response.filter(i => i.id)));
    const columns = [
        {field: 'id', headerName: 'ID', width: 0},
        {field: 'title', headerName: 'Title', width: 300},
        {field: 'code', headerName: 'Code'},
        // {field: 'category', headerName: 'Category'},
        // {field: 'format', headerName: 'Format'},
        {
            field: 'actions',
            headerName: 'Actions',
            width: 300,
            renderCell: (params) => (
                <div>
                    <Button color="primary"
                            onClick={() => {
                                history.push('/cardTypes/' + params.getValue('id'))
                            }}><EditIcon/></Button>
                    <Button color="secondary"
                            onClick={() => {
                                deleteFeeConfirm(params.getValue('id'))
                            }}><DeleteIcon/></Button>
                </div>
            )
        }
    ];
    useEffect(() => {
        refresh()
    }, []);
    const create = () => null;
    return (
        <div style={{height: 400, width: '100%'}}>
            <div className={classes.searchContainer}>
                <Button
                    className={classes.success}
                    variant="contained"
                    color="primary"
                    startIcon={<AddCircle/>}
                    onClick={() => history.push('/cardTypes/0')}>Create Card Type</Button>
            </div>
            <DataGrid rows={rows} columns={columns} pageSize={5}/>
            <Dialog fullScreen={fullScreen} open={openConfirm} onClose={handleCloseConfirm}
                    aria-labelledby="responsive-dialog-title">
                <DialogTitle id="responsive-dialog-title">{"Delete User Group?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={deleteItem} variant="contained" color="primary">
                        Yes
                    </Button>
                    <Button onClick={handleCloseConfirm} color="primary" autoFocus>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const styles = makeStyles((theme) => ({
    searchContainer: {
        width: '100%',
        marginBottom: '10px',
    },
    success: {
        backgroundColor: '#5cb85c',
        "&:hover": {
            backgroundColor: '#4fa14f',
        }
    }
}));
