import React, {useEffect} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import DashboardCart from './DashboardCart';
import apiCall from "../Api";
import IconPeople from '@material-ui/icons/People'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

export default function Dashboard(props) {
    props.setTitle('Dashboard')
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const user = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : null;
    const isAdmin = user === null;
    const [stats, setStats] = React.useState({user: {}, acct: {}, tran: {}, charge: {}});
    useEffect(() => {
        apiCall('/admins/stats').then(res => setStats(res.response))
    }, []);
    return (
        <>
            {isAdmin &&
            <Grid container spacing={3}>

                {/* Recent DashboardCart */}
                <Grid item xs={12} md={3} lg={3}>
                    <Paper className={fixedHeightPaper}>
                        <DashboardCart title="Pending Customers" Icon={IconPeople} value={stats.user.cnt}
                                       date={stats.user.maxDate}
                                       link='/pending-customers'/>
                    </Paper>
                </Grid>
                {/* Recent DashboardCart */}
                <Grid item xs={12} md={3} lg={3}>
                    <Paper className={fixedHeightPaper}>
                        <DashboardCart title="Pending Accounts" Icon={AccountBalanceIcon} value={stats.acct.cnt}
                                       date={stats.acct.maxDate}
                                       link='/pending-accounts'/>
                    </Paper>
                </Grid>
                {/* Recent DashboardCart */}
                <Grid item xs={12} md={3} lg={3}>
                    <Paper className={fixedHeightPaper}>
                        <DashboardCart title="Pending Outward" Icon={AttachMoneyIcon} value={stats.tran.cnt}
                                       date={stats.tran.maxDate}
                                       link='/pending-transactions'/>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <Paper className={fixedHeightPaper}>
                        <DashboardCart title="Pending Inward" Icon={MonetizationOnIcon}
                                       value={stats.charge.cnt} date={stats.charge.maxDate}
                                       link='/pending-charges'/>
                    </Paper>
                </Grid>
                {/* Chart */}
                {/*<Grid item xs={12}>*/}
                {/*  <Paper className={fixedHeightPaper}>*/}
                {/*    <Chart/>*/}
                {/*  </Paper>*/}
                {/*</Grid>*/}
                {/*/!* Recent Orders *!/*/}
                {/*<Grid item xs={12}>*/}
                {/*  <Paper className={classes.paper}>*/}
                {/*    <Orders/>*/}
                {/*  </Paper>*/}
                {/*</Grid>*/}
            </Grid>
            }
            {!isAdmin &&
            <Alert severity="info">Select One Of Side Bar Menus</Alert>
            }
        </>
    );
}
