import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

function Fallback() {
  return <div>{'...'}</div>;
}

function DebouncedMount({ component, fallback = Fallback, delay = 300 }) {
  const [Component, setComponent] = useState(() => fallback);
  const refContainer = useRef();
  clearTimeout(refContainer.current);
  useEffect(() => {
    refContainer.current = setTimeout(() => {
      setComponent(() => component);
    }, delay);
  }, [component,]);

  return <Component />;
}

DebouncedMount.propTypes = {
  component: PropTypes.any,
  componentProps: PropTypes.object,
  fallback: PropTypes.any,
  delay: PropTypes.number
};

export default DebouncedMount;
