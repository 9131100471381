import * as React from 'react';
import {useEffect, useState} from 'react';
import {DataGrid} from '@material-ui/data-grid';
import {Button} from "@material-ui/core";
import {useHistory} from "react-router";
import apiCall from "../../Api";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import AddCircle from "@material-ui/icons/AddCircle";
import {makeStyles} from "@material-ui/core/styles";

const initRows = [];

export default function DataTable(props) {
  const [rows, setRows] = useState(initRows);
  const history = useHistory();
  const classes = styles();
  const refresh = () => apiCall('/admins/accounts/accounts/status/NEW').then(x => x.response && x.response.length && setRows(x.response));
  const setStatus = (id, status) => {
    apiCall('/admins/accounts/accounts/' + id, {status}, 'PUT').then((res) => {
      if (res.response && res.response.accountNo)
        alert('Account successfully created. \nAccount Number: ' + res.response.accountNo);
      else if (res.message)
        alert(res.message);
      refresh();
    })
  }
  const columns = [
    {field: 'id', headerName: 'ID', width: 70},
    {
      field: 'firstName',
      headerName: 'First name',
      width: 130,
      valueGetter: (params) => params.getValue('user').firstName
    },
    {
      field: 'lastName', headerName: 'Last name', width: 130,
      valueGetter: (params) => params.getValue('user').lastName
    },
    {
      field: 'accountType',
      headerName: 'Account Type',
      width: 180
    },
    {
      field: 'currency',
      headerName: 'Currency',
      width: 130,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      width: 280,
      renderCell: (params) => (
        <div>
          <Button variant='contained' color='primary' style={{marginRight: 10}}
                  onClick={() => setStatus(params.getValue('id'), 'APPROVED')}><CheckIcon/> Approve</Button>
          <Button variant='contained' color='secondary'
                  onClick={() => setStatus(params.getValue('id'), 'REJECTED')}><ClearIcon/> Reject</Button>
        </div>)
    },
  ];
  props.setTitle('Pending Accounts')
  useEffect(() => {
    refresh()
  }, []);
  return (
    <div style={{height: 400, width: '100%'}}>
      <DataGrid rows={rows} columns={columns} pageSize={5}/>
    </div>
  );
}

const styles = makeStyles((theme) => ({
  searchContainer: {
    width: '100%',
    marginBottom: '10px',
  },
  success: {
    backgroundColor: '#5cb85c',
    "&:hover": {
      backgroundColor: '#4fa14f',
    }
  }
}));
