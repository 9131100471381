import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'qs';

function useTab(configs) {
  const keepSelected = configs.keepSelected ?? true;
  const history = useHistory();

  const getSelectedTab = () => {
    if (keepSelected) {
      const { t: selectedTab } = qs.parse(history.location.search.substr(1));
      return parseInt(selectedTab) || 0;
    }
    return configs.value || 0;
  };

  const [value, setValue] = useState(getSelectedTab);

  return {
    value,
    onChange: index => {
      if (index === value) return;
      setValue(index);
      const restQueryStrings = qs.parse(history.location.search.substr(1));
      const newQueryStrings = qs.stringify({
        ...restQueryStrings,
        t: index
      });
      if (keepSelected) history.push(`${history.location.pathname}?${newQueryStrings}`);
    },
    items: configs.items
  };
}

export default useTab;
