import React from 'react'
import {createStyles, makeStyles} from '@material-ui/core/styles'
// import { SvgIconProps } from '@material-ui/core/SvgIcon'
import List from '@material-ui/core/List'

import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Collapse from '@material-ui/core/Collapse'

import IconExpandLess from '@material-ui/icons/ExpandLess'
import IconExpandMore from '@material-ui/icons/ExpandMore'

import AppMenuItemComponent from './AppMenuItemComponent'


const AppMenuItem = props => {
  const {name, link, click, Icon,type, items = []} = props;
  const user = JSON.parse(sessionStorage.getItem('user'));
  const disabled = !link && !click && !items.length;
  const classes = useStyles()
  const isExpandable = items && items.length > 0
  const [open, setOpen] = React.useState(false)

  function handleClick() {
    setOpen(!open)
    click && click()
      if(click){ window.location.href = window.location.origin}
  }

  const MenuItemRoot = (
    <AppMenuItemComponent className={classes.menuItem} disabled={disabled} link={link} onClick={handleClick}>
      {/* Display an icon if any */}
      {!!Icon && (
        <ListItemIcon className={classes.menuItemIcon}>
          <Icon/>
        </ListItemIcon>
      )}
      <ListItemText primary={name} inset={!Icon}/>
      {/* Display the expand menu if the item has children */}
      {isExpandable && !open && <IconExpandMore/>}
      {isExpandable && open && <IconExpandLess/>}
    </AppMenuItemComponent>
  )

  const MenuItemChildren = isExpandable ? (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Divider/>
      <List component="div" disablePadding>
        {items.map((item, index) => (
          <AppMenuItem {...item} key={index}/>
        ))}
      </List>
    </Collapse>
  ) : null

  return (
    <>
      {((!user&&[1,3].indexOf(type)>-1)||(user&&[1,2].indexOf(type)>-1))&&MenuItemRoot}
      {((!user&&[1,3].indexOf(type)>-1)||(user&&[1,2].indexOf(type)>-1))&&MenuItemChildren}
    </>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    menuItem: {
      '&.active': {
        background: 'rgba(255, 255, 255, 0.08)',
        '& .MuiListItemIcon-root': {
          color: '#fff',
        },
      },
    },
    menuItemIcon: {
      color: '#fff',
    },
  }),
)

export default AppMenuItem
