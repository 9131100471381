import * as React from "react";
import {useEffect, useState} from "react";
import {Grid} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import apiCall from "../../Api";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router";

const initAccountType =()=> {
    return {
        title: '',
        currency: '',
        code: '',
        maintenanceFeeEnabled: false,
        monthlyMaintenanceFee: '',
        minimumBalanceFeeEnabled: false,
        minimumBalanceFee: '',
        minimumBalanceFeeThreashold: '',
        interestRateEnabled: false,
        interestRate: '',
        interestRateCalculationType: '',
        interestRateCalculationPeriod: '',
        overDraftEnabled: '',
        overDraftLimit: '',
        overDraftAnnualRate: '',
        overDraftFixedFee: '',
        feesCalculationDay: '',
        feesCalculationPeriod: '',
        feesCalculationType: ''
    };
}

export default function EditAccountType(props) {
    props.setTitle('Edit Account Tye')
    const {id} = props.match.params;
    const history = useHistory();
    const classes = useStyles();
    const [accountType, setAccountType] = useState(initAccountType);
    const [currencies, setCurrencies] = useState([]);
    const setItem = (code, val) => {
        accountType[code] = val;
        setAccountType({...accountType})
    }
    const save=()=>{
        let url = '/admins/account-types';
        let method = 'POST';
        if(id!=='0'){
            url+='/'+id;
            method = 'PUT'
        }
        apiCall(url,{title: accountType.title,code: accountType.code, currency: accountType.currency.currency},method).then(r=> history.goBack())
    }

    useEffect(() => {
        apiCall('/admins/currencies',).then(r => setCurrencies(r.response))
        if(id!=='0'){
            apiCall('/admins/account-types').then(r=>{
                setAccountType({...r.response.filter(a=> a.id.toString()===id)[0]})
            })
        }
    }, [])

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required fullWidth
                        className={classes.requiredField}
                        label="Title"
                        variant="outlined"
                        value={accountType.title}
                        onChange={(p) => setItem('title', p.target.value)}
                        helperText={accountType.title ? '' : 'Title is Required.'}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        className={classes.requiredField}
                        value={accountType.currency}
                        onChange={(e, p) => setItem('currency', p)}
                        options={currencies}
                        classes={{
                            option: classes.option,
                        }}
                        autoHighlight
                        getOptionLabel={(option) => option?.currency}
                        renderOption={(option) => (
                            <React.Fragment>
                                {option?.currency}
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField
                                required
                                {...params}
                                label="Currency"
                                variant="outlined"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                                helperText={accountType.currency ? '' : 'Currency is requried'}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required fullWidth
                        className={classes.requiredField}
                        label="Code"
                        inputProps={{
                            maxLength: 4
                        }}
                        variant="outlined"
                        value={accountType.code}
                        onChange={(p) => setItem('code', p.target.value)}
                        helperText={accountType.code&& accountType.code.length===4 ? '' : 'Code is Required.'}
                    />
                </Grid>
                {/*<Grid item xs={12}>*/}
                {/*    <FormControlLabel control={<Checkbox checked={accountType.maintenanceFeeEnabled} color='primary'*/}
                {/*                                         onChange={(e, p) => setItem('maintenanceFeeEnabled', p)}/>}*/}
                {/*                      label="Monthly Maintenance"/>*/}
                {/*</Grid>*/}
                {/*{accountType.maintenanceFeeEnabled &&*/}
                {/*<Grid item xs={12}>*/}
                {/*    <TextField*/}
                {/*        required fullWidth*/}
                {/*        className={classes.requiredField}*/}
                {/*        label="Fee Amount"*/}
                {/*        variant="outlined"*/}
                {/*        value={accountType.monthlyMaintenanceFee}*/}
                {/*        onChange={(p) => setItem('monthlyMaintenanceFee', p.target.value)}*/}
                {/*        helperText={accountType.monthlyMaintenanceFee ? '' : 'Fee Amount is Required.'}*/}
                {/*    />*/}
                {/*</Grid>*/}
                {/*}*/}
                {/*<Grid item xs={12}>*/}
                {/*    <FormControlLabel control={<Checkbox checked={accountType.minimumBalanceFeeEnabled} color='primary'*/}
                {/*                                         onChange={(e, p) => setItem('minimumBalanceFeeEnabled', p)}/>}*/}
                {/*                      label="Minimum Balance"/>*/}
                {/*</Grid>*/}
                {/*{accountType.minimumBalanceFeeEnabled &&*/}
                {/*<>*/}
                {/*    <Grid item xs={4}>*/}
                {/*        <TextField*/}
                {/*            required fullWidth*/}
                {/*            className={classes.requiredField}*/}
                {/*            label="minimumBalanceFee"*/}
                {/*            variant="outlined"*/}
                {/*            value={accountType.minimumBalanceFee}*/}
                {/*            onChange={(p) => setItem('minimumBalanceFee', p.target.value)}*/}
                {/*            helperText={accountType.minimumBalanceFee ? '' : 'minimumBalanceFee is Required.'}*/}
                {/*        />*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={4}>*/}
                {/*        <TextField*/}
                {/*            required fullWidth*/}
                {/*            className={classes.requiredField}*/}
                {/*            label="Fee Amount"*/}
                {/*            variant="outlined"*/}
                {/*            value={accountType.minimumBalanceFeeThreashold}*/}
                {/*            onChange={(p) => setItem('minimumBalanceFeeThreashold', p.target.value)}*/}
                {/*            helperText={accountType.minimumBalanceFeeThreashold ? '' : 'minimumBalanceFeeThreashold is Required.'}*/}
                {/*        />*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={4}>*/}
                {/*        <TextField*/}
                {/*            required fullWidth*/}
                {/*            className={classes.requiredField}*/}
                {/*            label="Fee Amount"*/}
                {/*            variant="outlined"*/}
                {/*            value={accountType.minimumBalanceFeeThreashold}*/}
                {/*            onChange={(p) => setItem('minimumBalanceFeeThreashold', p.target.value)}*/}
                {/*            helperText={accountType.minimumBalanceFeeThreashold ? '' : 'minimumBalanceFeeThreashold is Required.'}*/}
                {/*        />*/}
                {/*    </Grid>*/}
                {/*</>*/}
                {/*}*/}
                {/*<Grid item xs={12}>*/}
                {/*    <FormControlLabel control={<Checkbox checked={accountType.interestRateEnabled} color='primary'*/}
                {/*                                         onChange={(e, p) => setItem('interestRateEnabled', p)}/>}*/}
                {/*                      label="Interest Rate"/>*/}
                {/*</Grid>*/}
                {/*{accountType.interestRateEnabled &&*/}
                {/*<>*/}
                {/*    <Grid item xs={4}>*/}
                {/*        <TextField*/}
                {/*            required fullWidth*/}
                {/*            className={classes.requiredField}*/}
                {/*            label="interestRate"*/}
                {/*            variant="outlined"*/}
                {/*            value={accountType.interestRate}*/}
                {/*            onChange={(p) => setItem('interestRate', p.target.value)}*/}
                {/*            helperText={accountType.interestRate ? '' : 'interestRate is Required.'}*/}
                {/*        />*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={4}>*/}
                {/*        <TextField*/}
                {/*            required fullWidth*/}
                {/*            className={classes.requiredField}*/}
                {/*            label="interestRateCalculationType"*/}
                {/*            variant="outlined"*/}
                {/*            value={accountType.interestRateCalculationType}*/}
                {/*            onChange={(p) => setItem('interestRateCalculationType', p.target.value)}*/}
                {/*            helperText={accountType.interestRateCalculationType ? '' : 'interestRateCalculationType is Required.'}*/}
                {/*        />*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={4}>*/}
                {/*        <TextField*/}
                {/*            required fullWidth*/}
                {/*            className={classes.requiredField}*/}
                {/*            label="interestRateCalculationPeriod"*/}
                {/*            variant="outlined"*/}
                {/*            value={accountType.interestRateCalculationPeriod}*/}
                {/*            onChange={(p) => setItem('interestRateCalculationPeriod', p.target.value)}*/}
                {/*            helperText={accountType.interestRateCalculationPeriod ? '' : 'interestRateCalculationPeriod is Required.'}*/}
                {/*        />*/}
                {/*    </Grid>*/}
                {/*</>*/}
                {/*}*/}
                {/*<Grid item xs={12}>*/}
                {/*    <FormControlLabel control={<Checkbox checked={accountType.overDraftEnabled} color='primary'*/}
                {/*                                         onChange={(e, p) => setItem('overDraftEnabled', p)}/>}*/}
                {/*                      label="Over Draft"/>*/}
                {/*</Grid>*/}
                {/*{accountType.overDraftEnabled &&*/}
                {/*<>*/}
                {/*    <Grid item xs={4}>*/}
                {/*        <TextField*/}
                {/*            required fullWidth*/}
                {/*            className={classes.requiredField}*/}
                {/*            label="Limit Amount"*/}
                {/*            variant="outlined"*/}
                {/*            value={accountType.overDraftLimit}*/}
                {/*            onChange={(p) => setItem('overDraftLimit', p.target.value)}*/}
                {/*            helperText={accountType.overDraftLimit ? '' : 'Limit Amount is Required.'}*/}
                {/*        />*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={4}>*/}
                {/*        <TextField*/}
                {/*            required fullWidth*/}
                {/*            className={classes.requiredField}*/}
                {/*            label="Annual Rate"*/}
                {/*            variant="outlined"*/}
                {/*            value={accountType.overDraftAnnualRate}*/}
                {/*            onChange={(p) => setItem('overDraftAnnualRate', p.target.value)}*/}
                {/*            helperText={accountType.overDraftAnnualRate ? '' : 'Annual Rate is Required.'}*/}
                {/*        />*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={4}>*/}
                {/*        <TextField*/}
                {/*            required fullWidth*/}
                {/*            className={classes.requiredField}*/}
                {/*            label="Fixed Fee"*/}
                {/*            variant="outlined"*/}
                {/*            value={accountType.overDraftFixedFee}*/}
                {/*            onChange={(p) => setItem('overDraftFixedFee', p.target.value)}*/}
                {/*            helperText={accountType.overDraftFixedFee ? '' : 'Fixed Fee is Required.'}*/}
                {/*        />*/}
                {/*    </Grid>*/}
                {/*</>*/}
                {/*}*/}
            </Grid>
            <hr/>
            <Button variant="contained" color="primary" onClick={() => save()}
                    disabled={!(accountType.title&&accountType.code&&accountType.currency&&accountType.code.length===4)}>
                Save Changes
            </Button>
            &nbsp;
            <Button variant="outlined" onClick={() => history.goBack()}>
                Cancel
            </Button>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: '100%'
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    requiredField: {
        "& .MuiFormHelperText-root": {
            color: 'red'
        }
    }
}));
