import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
// import bgImage from '../assets/images/login-bg.jpeg';
import bgImage from '../assets/images/site-background2.jpg';
import logo from '../assets/images/logo-sq.jpg';
import Copyright from "../copyright";
import {useHistory} from "react-router"; 

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(' + bgImage + ')',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#393535'
  },
}));
export default function SignInSide(props) {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const history = useHistory();
 
const signin = (e, username, password) => {
   
      // if (username !== 'user1' || password !=='user1') {
      //   setError("user and password invalid");
      //   sessionStorage.removeItem('token');
      //   sessionStorage.removeItem('user');
      //   alert('user and password invalid')
      //   // setToken('');
      // } else { 
      //   sessionStorage.setItem('token', "dsadnuiohgnadhggoiasdhniohsagn");        
      //   // sessionStorage.setItem('token', data.response.token);
       
      //     let user={"registeredAddress":"IRSD15","residencePhone":null,"mobileVerificationCode":null,"emailVerificationCode":null,"correspondenceAddress":null,"userGroup":null,"firstName":"saaed","id":21,"addressProofImage":null,"permanentAddress":null,"middleName":null,"sourceOfIncome":null,"passportIssueDate":null,"officeAddress":null,"idCardImage":null,"homeOwnershipStatus":null,"educationLevel":null,"class":"digital.neobank.accounting.entity.customer.CmUser","updateDate":null,"noOfChildren":null,"monthlyIncome":null,"dateOfBirth":null,"officePhone":null,"adminComment":null,"createDate":"2023-11-04","emailVerified":false,"nationalIdNo":null,"mobile":"091212456215","title":null,"maritalStatus":null,"passportIssuePlace":null,"profileImage":null,"mobileVerified":false,"username":"user1","passportImage":null,"countryOfResidence":"IR","password":"123456","signImage":null,"placeOfBirth":null,"occupation":null,"nationality":null,"status":"NEW","companyName":null,"lastName":"abedi","enabled":false,"residentialAddress":"tehran","email":"test@yahoo.com","passportNo":null}
      //     sessionStorage.setItem('user', JSON.stringify(user));
      //     // sessionStorage.setItem('user', JSON.stringify(data.response.user));
      
      //   history.push('/');
      //   window.location.reload();
      //   setError('');
      //   // setToken(data.response.token);
      //   // setToken(data.response.token);

      // }
    
 
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({username, password})
  };
  fetch(window.API_HOST + '/admins/login', requestOptions)
    .then(response => response.json())
    .then(data => {
      if (data.status === 'FAILURE' || data.error) {
        setError(data.message);
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('user');
        alert(data.message)
        // setToken('');
      } else {         
        sessionStorage.setItem('token', data.response.token);
        if(data.response.user){
          // let user={"registeredAddress":"IRSD15","residencePhone":null,"mobileVerificationCode":null,"emailVerificationCode":null,"correspondenceAddress":null,"userGroup":null,"firstName":"saaed","id":21,"addressProofImage":null,"permanentAddress":null,"middleName":null,"sourceOfIncome":null,"passportIssueDate":null,"officeAddress":null,"idCardImage":null,"homeOwnershipStatus":null,"educationLevel":null,"class":"digital.neobank.accounting.entity.customer.CmUser","updateDate":null,"noOfChildren":null,"monthlyIncome":null,"dateOfBirth":null,"officePhone":null,"adminComment":null,"createDate":"2023-11-04","emailVerified":false,"nationalIdNo":null,"mobile":"091212456215","title":null,"maritalStatus":null,"passportIssuePlace":null,"profileImage":null,"mobileVerified":false,"username":"user1","passportImage":null,"countryOfResidence":"IR","password":"123456","signImage":null,"placeOfBirth":null,"occupation":null,"nationality":null,"status":"NEW","companyName":null,"lastName":"abedi","enabled":false,"residentialAddress":"tehran","email":"test@yahoo.com","passportNo":null}
          // sessionStorage.setItem('user', JSON.stringify(user));
          sessionStorage.setItem('user', JSON.stringify(data.response.user));
        }
        history.push('/');
        window.location.reload();
        setError('');
        // setToken(data.response.token);
        // setToken(data.response.token);

      }
    });
  e.preventDefault();
}

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline/>
      <Grid item xs={false} sm={4} md={8} lg={9} xl={9} className={classes.image}/>
      <Grid item xs={12} sm={8} md={4} lg={3} xl={3} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar variant="square" className={classes.avatar}>
            <img src={logo} style={{width:'100%'}}/>
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate onSubmit={(e) => signin(e, username, password)}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary"/>}
              label="Remember me"
            />
            {error && (<Alert severity="error">{error}</Alert>)}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
            <Grid container>
               
              <Grid item>
                <Link href="/register" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright/>
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
