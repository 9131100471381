import * as React from "react";
import {useEffect, useState} from "react";
import apiCall from "../../Api";
import {Grid, useTheme} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Chip from "@material-ui/core/Chip";
import {makeStyles} from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import NumberFormat from "react-number-format";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router";

const initTranTypes = [];

export default function EditFee(props) {
    props.setTitle('Edit Fee')
    const {id} = props.match.params;
    const classes = useStyles();
    const history = useHistory();
    const [fee, setFee] = useState({title: '', tranType: {}, items: [{ccy:''}], userGroups: []});
    const [tranTypes, setTranTypes] = useState(initTranTypes);
    const [userGroups, setUserGroups] = useState(initTranTypes);
    const [currencies, setCurrencies] = useState(initTranTypes);
    const setItem = (code, val)=>{
        fee[code] = val;
        setFee({...fee})
    }
    const tranTypesCall = () => apiCall('/admins/fee/tran-types').then(r => setTranTypes(r.response));
    const userGroupsCall = () => apiCall('/admins/user-groups').then(r => setUserGroups(r.response));
    const currencyList = ()=> apiCall('/admins/currencies').then(r=>setCurrencies(r.response))
    const save = ()=>{
        const entity = {
            title: fee.title,
            userGroupIds: fee.userGroups.map(u=>u.id),
            tranTypeId: fee.tranType.id,
            items: fee.items.map(i=>{
                return {
                    ccy: i.ccy,
                    fixed: i.fixed,
                    percentage: i.percentage,
                    max: i.max,
                    min: i.min
                }
            })
        }
        let url = '/admins/fee';
        let method = 'POST'
        if(id!='0'){
            url += '/'+id;
            method = 'PUT'
        }
        apiCall(url,entity, method).then(r=> history.goBack());
    }

    useEffect(() => {
        tranTypesCall();
        userGroupsCall();
        currencyList();
        if(id!='0'){
            apiCall('/admins/fee/'+id).then(r=> {
                const res = r.response
                const rfee = {
                    title: res.title,
                    tranType: res.tranType,
                    userGroups: res.userGroups,
                    items: res.items.map(i=> {
                        return {
                            ccy: i.ccy,
                            max: i.max,
                            fixed: i.fixed,
                            percentage: i.percentage,
                            min: i.min
                        }
                    })
                };
                setFee({...rfee})
            })
        }
    }, []);


    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        required
                        className={classes.requiredField}
                        label="Name"
                        name="title"
                        id="init-balance"
                        variant="outlined"
                        value={fee.title}
                        style={{width: 300}}
                        onChange={(p) => {
                            fee.title = p.target.value;
                            setFee({...fee})
                        }}
                        helperText={fee.title?'':'Name is Required.'}
                        />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        className={classes.requiredField}
                        value={fee.tranType}
                        style={{width: 300}}
                        onChange={(e, p) => setItem('tranType', p)}
                        options={tranTypes}
                        classes={{
                            option: classes.option,
                        }}
                        autoHighlight
                        getOptionLabel={(option) => option.title }
                        renderOption={(option) => (
                            <React.Fragment>
                                {option.title }
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField
                                required
                                {...params}
                                label="Tran Type"
                                variant="outlined"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                                helperText={fee.tranType&&fee.tranType.id?'':'Tran Type is requried'}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl className={classes.formControl} variant='outlined'>
                        <InputLabel htmlFor="accountType">Customer Groups
                            <span aria-hidden="true"
                                  className="MuiFormLabel-asterisk MuiInputLabel-asterisk">&thinsp;*</span>
                        </InputLabel>
                        <Select
                            multiple
                            value={fee.userGroups}
                            style={{width: 300}}
                            onChange={(e)=>{
                                fee.userGroups = e.target.value;
                                setFee({...fee})
                            }}
                            inputProps={{
                                name: 'Customer Groups',
                                id: 'AccountType',
                            }}
                            renderValue={(selected) => (
                                <div className={classes.chips}>
                                    {selected.map((value) => (
                                        <Chip key={value.id} label={value.title} className={classes.chip} />
                                    ))}
                                </div>
                            )}
                            MenuProps={MenuProps}
                        >
                            {userGroups.map((u) => (
                                <MenuItem key={u.id} value={u}>
                                    {u.title}
                                </MenuItem>
                            ))}
                        </Select>
                        <span className={classes.requiredField}>
                            <span className="MuiFormHelperText-root">{fee.userGroups&&fee.userGroups.length>0 ? '' : 'Tran Type is Required'}</span>
                        </span>
                    </FormControl>
                </Grid>
                {fee.items.map((item,index)=> (
                    <Grid item xs={12} lg={8} container spacing={3}>
                        {fee.items.length>1&&
                        <Grid item xs={12}>
                            <IconButton aria-label="delete" className={classes.margin} onClick={()=>{
                                fee.items.splice(index,1)
                                setFee({...fee})
                            }}>
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                        }
                        <Grid item xs={12}>
                            <Autocomplete
                                className={classes.requiredField}
                                value={item.ccy}
                                onChange={(e, p) => {
                                    item.ccy= p;
                                    setFee({...fee})
                                }}
                                options={currencies.map(c=>c.currency.trim())}
                                classes={{
                                    option: classes.option,
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        required
                                        {...params}
                                        label="Currency"
                                        variant="outlined"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                        helperText={item.ccy?'':'Currency is requried'}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Fixed"
                                variant="outlined"
                                value={item.fixed}
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                }}
                                onChange={(p) => {
                                    item.fixed = p.target.value;
                                    setFee({...fee})
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Percentage"
                                variant="outlined"
                                value={item.percentage}
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                }}
                                onChange={(p) => {
                                    item.percentage = p.target.value;
                                    setFee({...fee})
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Min"
                                variant="outlined"
                                value={item.min}
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                }}
                                onChange={(p) => {
                                    item.min = p.target.value;
                                    setFee({...fee})
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Max"
                                variant="outlined"
                                value={item.max}
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                }}
                                onChange={(p) => {
                                    item.max = p.target.value;
                                    setFee({...fee})
                                }}
                            />
                        </Grid>
                    </Grid>
                ))}
                <Grid item xs={12}>
                    <Link
                        component="button"
                        variant="body2"
                        onClick={() => {
                            fee.items.push({ccy:{}, max: ''})
                            setFee({...fee})
                        }}
                    >
                        + Add New
                    </Link>
                </Grid>
            </Grid>
            <hr/>
            <Button variant="contained" color="primary" onClick={() => save()}
                    disabled={!(fee.title&&fee.tranType&&fee.tranType.id&&fee.userGroups&&fee.userGroups.length>0&&fee.items&&(fee.items.length>0?fee.items.filter(i=>!i.ccy)?.length===0:true))}>
                Save Changes
            </Button>
            &nbsp;
            <Button variant="outlined" onClick={() => history.goBack()}>
                Cancel
            </Button>
        </div>
    )
}

function getStyles(name, personName, theme) {
    return {
        fontWeight:theme.typography.fontWeightRegular
    };
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: '100%'
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    requiredField: {
        "& .MuiFormHelperText-root":{
            color: 'red'
        }
    }
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
function NumberFormatCustom(props) {
    const {inputRef, onChange, ...other} = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
        />
    );
}
