import * as React from 'react';
import {useEffect, useState} from 'react';
import {useHistory} from "react-router";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {makeStyles} from "@material-ui/core/styles";
import {Button, Grid} from "@material-ui/core";
import apiCall from "../../Api";
import InputAdornment from "@material-ui/core/InputAdornment";
import {AccountCircle} from "@material-ui/icons";
import NumberFormat from 'react-number-format';

const useStyles = makeStyles((theme) => ({
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '100%',
    },
}));

const initUsers = [];
export default function CreateAccount(props) {
    props.setTitle('Create Account Request');
    const history = useHistory();
    const classes = useStyles();
    const [account, setAccount] = React.useState({
        accType: '',
        user: {id: null},
        ccy: {id: null}
    });
    const [users, setUsers] = React.useState(initUsers);
    const [currencies, setCurrencies] = useState(initUsers);
    const [accountTypes,setAccountTypes] = useState(initUsers);

    useEffect(() => {
        apiCall('/admins/customers/status/APPROVED').then(x => setUsers(x.response));
        apiCall('/admins/account-types').then(x => setAccountTypes(x.response));
        apiCall('/admins/currencies', ).then(r=> setCurrencies(r.response))
    }, [account]);
    const save = ()=> {
        apiCall('/admins/accounts/accounts',{accountType: account.accType.code, currency: account.ccy.currency, userId: account.user.id}, 'POST').then(r=> history.goBack())
    }
    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Autocomplete
                        value={account.accType}
                        onChange={(e, p) => {
                            account.accType = p;
                            setAccount({...account})
                        }}
                        id="account-type"
                        style={{width: 300}}
                        options={accountTypes}
                        classes={{
                            option: classes.option,
                        }}
                        autoHighlight
                        getOptionLabel={(option) => option.title}
                        renderOption={(option) => (
                            <React.Fragment>
                                {option.title}
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Account Type"
                                variant="outlined"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        value={account.user}
                        onChange={(e, p) => {
                            account.user = p;
                            setAccount({...account})
                        }}
                        id="user"
                        style={{width: 300}}
                        options={users}
                        classes={{
                            option: classes.option,
                        }}
                        autoHighlight
                        getOptionLabel={(option) => option.firstName?`${option.firstName} ${option.lastName}`:option.username}
                        renderOption={(option) => (
                            <React.Fragment>
                                {option.firstName?`${option.firstName} ${option.lastName}`:option.username}
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="User"
                                variant="outlined"
                                inputProps={{
                                    ...params.inputProps,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccountCircle/>
                                        </InputAdornment>
                                    ),
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                </Grid>

            </Grid>
            <hr/>
            <Button variant="contained" color="primary" onClick={() => save()}>
                Create
            </Button>
            &nbsp;
            <Button variant="outlined" onClick={() => history.goBack()}>
                Cancel
            </Button>
        </div>
    )
}

function NumberFormatCustom(props) {
    const {inputRef, onChange, ...other} = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            prefix="$"
        />
    );
}
