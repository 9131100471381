function apiCall(url, entity, method) {
  const requestOptions = {
    method: entity ? (method ? method : 'POST') : 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
  };
  if (entity)
    requestOptions.body = JSON.stringify(entity)
  return fetch(window.API_HOST + url, requestOptions)
    .then(response => response.json())
    .then(json => {
      if (json.status === 'FAILURE') {
        if (json.message === 'Access is denied') {
          sessionStorage.removeItem('token');
          window.location.reload()
        } else
          alert(json.message)
      }
      return json;
    });
}

export default apiCall;
