import * as React from 'react';
import {useEffect, useState} from 'react';
import apiCall from "../Api";
import {Button, Grid} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import NumberFormat from "react-number-format";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import {useHistory} from "react-router";

const initChargeAccount = () => {
    return {
        amount: '',
        destinationAccountNo: {accountNo: ''},
        destinationBank: '',
        destinationIbanNo: '',
        memo: '',
        sourceBank: '',
        sourceIbanNo: '',
        user: {id: null}
    };
}

export default function ChargeRequest(props) {
    props.setTitle('Inward Request');
    const classes = styles();
    const history = useHistory();
    const user = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : null;
    const isAdmin = user === null;
    const [chargeAccount, setChargeAccount] = useState(initChargeAccount);
    const [users, setUsers] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const loadAccounts = (user) => {
        chargeAccount.user = user;
        chargeAccount.destinationAccountNo = {accountNo: ''}
        setChargeAccount({...chargeAccount});
        setAccounts({...[]});
        if (user && user.id) {
            apiCall(`/admins/users/${user.id}/accounts`).then(r => setAccounts(r.response));
        }
    }
    const setItem = (code, val) => {
        chargeAccount[code] = val;
        setChargeAccount({...chargeAccount});
    }
    const chargeRequest = () => {
        const dto = {
            amount: chargeAccount.amount,
            destinationAccountNo: chargeAccount.destinationAccountNo.accountNo,
            destinationBank: chargeAccount.destinationBank,
            destinationIbanNo: chargeAccount.destinationIbanNo,
            memo: chargeAccount.memo,
            sourceBank: chargeAccount.sourceBank,
            sourceIbanNo: chargeAccount.sourceIbanNo,
            userId: chargeAccount.user.id
        }
        apiCall('/admins/transactions-charge', dto, 'POST').then(r => {
            if (r.status === 'SUCCESS') {
                alert('Saved Successfully')
                setChargeAccount({...initChargeAccount()})
            }
        })
    }
    useEffect(() => {
        apiCall('/admins/customers/status/APPROVED').then(x => setUsers(x.response));
        apiCall('/admins/accounts/accounts/status/APPROVED').then(r => setAccounts(r.response));
    }, []);

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        className={classes.requiredField}
                        variant="outlined"
                        value={chargeAccount.user}
                        options={isAdmin?users:[user]}
                        getOptionLabel={(option) => option.firstName ? `${option.firstName} ${option.lastName}` : ''}
                        onChange={(e, value) => loadAccounts(value)}
                        renderInput={(params) => <TextField required {...params} label="User" variant="outlined"
                                                            helperText={chargeAccount.user && chargeAccount.user.id ? '' : 'User Field is Required.'}/>}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        className={classes.requiredField}
                        options={chargeAccount.user && chargeAccount.user.id ? accounts : []}
                        value={chargeAccount.destinationAccountNo}
                        noOptionsText="Select User First"
                        getOptionLabel={(option) => option.accountNo}
                        onChange={(e, value) => setItem('destinationAccountNo', value)}
                        renderInput={(params) => <TextField required variant="outlined" {...params}
                                                            label="Destination Account"
                                                            helperText={chargeAccount.destinationAccountNo && chargeAccount.destinationAccountNo.accountNo ? '' : 'Destination Account is Required.'}/>}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        style={{width: 300}}
                        className={classes.requiredField}
                        helperText={chargeAccount.amount ? '' : 'Amount is Required.'}
                        label='Amount'
                        value={chargeAccount.amount}
                        variant='outlined'
                        InputProps={{inputComponent: NumberFormatCustom}}
                        onChange={(p) => setItem('amount', p.target.value)}/>
                </Grid>
                <Grid item xs={12} sm={6} container spacing={3}>
                    <Grid item xs={12}><span><b>Source Bank</b></span></Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            className={classes.requiredField}
                            label="Source Bank Name"
                            style={{width: '100%'}}
                            value={chargeAccount.sourceBank}
                            onChange={(p) => setItem('sourceBank', p.target.value)}
                            helperText={chargeAccount.sourceBank ? '' : "Source Bank Name is Required."}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            className={classes.requiredField}
                            label="Source IBAN No."
                            style={{width: '100%'}}
                            value={chargeAccount.sourceIbanNo}
                            onChange={(p) => setItem('sourceIbanNo', p.target.value)}
                            helperText={chargeAccount.sourceIbanNo ? '' : "Source Bank is Required."}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} container spacing={3}>
                    <Grid item xs={12}><span><b>Destination Bank</b></span></Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            className={classes.requiredField}
                            label="Destination Bank Name"
                            style={{width: '100%'}}
                            value={chargeAccount.destinationBank}
                            onChange={(p) => setItem('destinationBank', p.target.value)}
                            helperText={chargeAccount.destinationBank ? '' : "Destination Bank Name is Required."}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            className={classes.requiredField}
                            label="Destination IBAN No."
                            style={{width: '100%'}}
                            value={chargeAccount.destinationIbanNo}
                            onChange={(p) => setItem('destinationIbanNo', p.target.value)}
                            helperText={chargeAccount.destinationIbanNo ? '' : "Destination IBAN No. is Required."}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <span>Description</span>
                    <TextareaAutosize value={chargeAccount.memo}
                                      style={{width: '100%', fontSize: '16px', fontFamily: "Roboto"}} rowsMin={10}
                                      onChange={(p) => setItem('memo', p.target.value)}/>
                </Grid>
            </Grid>
            <hr/>
            <Button variant="contained" color="primary" onClick={() => chargeRequest()}
                    disabled={!(chargeAccount.user && chargeAccount.user.id && chargeAccount.destinationAccountNo && chargeAccount.destinationAccountNo.accountNo && chargeAccount.sourceIbanNo && chargeAccount.sourceBank && chargeAccount.destinationBank && chargeAccount.destinationIbanNo)}>
                Charge Request
            </Button>
            &nbsp;
            <Button variant="outlined" onClick={() => history.goBack()}>
                Cancel
            </Button>
        </div>
    )
}

const styles = makeStyles((theme) => ({
    searchContainer: {
        width: '100%',
        marginBottom: '10px',
    },
    success: {
        backgroundColor: '#5cb85c',
        "&:hover": {
            backgroundColor: '#4fa14f',
        }
    },
    requiredField: {
        "& .MuiFormHelperText-root": {
            color: 'red'
        }
    }
}));

function NumberFormatCustom(props) {
    const {inputRef, onChange, ...other} = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
        />
    );
}
