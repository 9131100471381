import * as React from 'react';
import {useEffect, useState} from 'react';
import {Grid} from "@material-ui/core";
import apiCall from "../Api";
import {makeStyles} from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import NumberFormat from "react-number-format";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import {loadStripe} from '@stripe/stripe-js';
const initCustomer = [];
const initAccounts = [];
const initTransfer =()=> {return {user: {id: null}, creditAcc: {}, debitAcc: {}, amount: '', memo: ''};}

export default function TransferBetweenAccounts(props) {

    const classes = useStyles();
    const history = useHistory();
    const user = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : null;
    const isAdmin = user === null;
    const [customers, setCustomers] = useState(initCustomer);
    const [accounts, setAccounts] = useState(initAccounts);
    const [transfer, setTransfer] = useState(initTransfer)
    const transferApi = async() => { 
        const stripePromise = loadStripe(
            "pk_test_6pRNASCoBOKtIshFeQd4XMUh"
          );
          const stripe = await stripePromise;
        apiCall('/wallet/depositTxn', {
            "amount": transfer.amount,
            "txnKey": transfer.memo,
            "userId": user.id,
        }, 'POST').then(r => {
            if (r.status === 'SUCCESS') {
                // alert("Transaction Success!");
                setTransfer({...initTransfer()})
                stripe.redirectToCheckout({ sessionId: 'cs_test_123456' });
                //  window.stripe.redirectToCheckout({ sessionId: 'cs_test_123456' });
            }
            else{
                setTransfer({...initTransfer()})
                alert("Transaction Faild!");
            }
        })
      

    }

    useEffect(() => {
        if(isAdmin){
            apiCall('/admins/customers/status/APPROVED').then(x => {
                setCustomers(x.response)
            });
        }else{
            setCustomers([user])
        }

        apiCall('/admins/accounts/accounts/status/APPROVED').then(r => setAccounts(r.response))
    }, [])

    props.setTitle('Transfer Between Accounts');
    return (
        <div>
            <Grid container spacing={2}>
        
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        className={classes.requiredField}
                        id="amount-number"
                        label="Amount "
                        value={transfer.amount}
                        style={{width: '100%'}}
                        onChange={(p) => {
                            transfer.amount = p.target.value;
                            setTransfer({...transfer})
                        }}
                        InputProps={{
                            inputComponent: NumberFormatCustom,
                        }}
                        helperText={transfer.amount ? '' : "Amount is Required."}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <span>Description</span>
                    <TextareaAutosize value={transfer.memo} style={{width: '100%', fontSize:'16px', fontFamily: "Roboto"}} rowsMin={3}
                                      onChange={(p) => {
                                          transfer.memo = p.target.value;
                                          setTransfer({...transfer})
                                      }}
                    />
                </Grid>
            </Grid>
            <hr/>
            <Button variant="contained" color="primary" onClick={() => transferApi()}
                    disabled={!( transfer.amount)}>
                Transfer
            </Button>
            &nbsp;
            <Button variant="outlined" onClick={() => history.goBack()}>
                Cancel
            </Button>
        </div>
    )
}

function NumberFormatCustom(props) {
    const {inputRef, onChange, ...other} = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
        />
    );
}

const useStyles = makeStyles((theme) => ({
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '100%',
    },
    requiredField: {
        "& .MuiFormHelperText-root": {
            color: 'red'
        }
    }
}));
