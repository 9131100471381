import * as React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { Button } from "@material-ui/core";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import apiCall from "../../Api";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

const initRows = [];

export default function DataTable(props) {
  const [rows, setRows] = useState(initRows);
  const history = useHistory();

  const columns = [
    {field: 'id', headerName: 'ID', width: 70},
    {
      field: 'firstName',
      headerName: 'First name',
      width: 130,
      valueGetter: (params) => params.getValue('user').firstName
    },
    {
      field: 'lastName', headerName: 'Last name', width: 130,
      valueGetter: (params) => params.getValue('user').lastName
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 130

    },
    {
      field: 'sourceBank',
      headerName: 'Source Bank',
      width: 180,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      width: 280,
      renderCell: (params) => (
        <Button
          onClick={() => history.push('/pending-charges/' + params.getValue('id'))}><KeyboardArrowRightIcon/></Button>)
    },
  ];
  props.setTitle('Pending Charge Account Requests')
  useEffect(() => {
    apiCall('/admins/transactions-charge/pending').then(x => setRows(x.response));
  }, [])
  return (
    <div style={{height: 400, width: '100%'}}>
      <DataGrid rows={rows} columns={columns} pageSize={5}/>
    </div>
  );
}
