import 'date-fns';
import * as React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { Button, Grid } from "@material-ui/core";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import apiCall from "../../Api";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { subMonths, format } from "date-fns";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import GetAppIcon from '@material-ui/icons/GetApp';

const initRows = [];

export default function DataTable(props) {
  const usr = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : null;
  const isAdmin = usr === null;
  const [rows, setRows] = useState(initRows);
  const [users, setUsers] = useState(initRows);
  const [accounts, setAccounts] = useState(initRows);
  const [account, setAccount] = useState();
  const [user, setUser] = useState();
  const [fromDate, setFromDate] = useState(subMonths(new Date(), 1));
  const [toDate, setToDate] = useState(new Date());

  const columns = [
    {field: 'transDate', headerName: 'Trans Date', width: 300},
    {field: 'amount', headerName: 'amount', width: 300,},
    {
      field: 'transKey', headerName: 'desc', width: 300,
    },
    {
      field: 'transType',
      headerName: 'Trans Type',
      width: 300,
    } 
  
  ];
  props.setTitle('Account Statement')
 
  // useEffect(() => {
  //   apiCall('/admins/users').then(x => setUsers(x.response));
  // }, []);
  useEffect(()=>{
    let url = '/wallet/getTxnList';
    let method = 'POST';
  
    apiCall(url, {userId: usr.id},method).then(x => setRows(x.response));
    // apiCall('/wallet/getTxnList', account, 'POST').then(x => setRows(x.response)); 
  },[])
  const loadAccounts = (user) => {
    setUser(user)
    setAccount(null)
    if (!user)
      return setAccounts(initRows)
    apiCall(`/admins/users/${user.id}/accounts`).then(x => setAccounts(x.response))
  }
  const loadStatement = () => {
    const from = format(fromDate, 'yyyy-MM-dd')
    const to = format(toDate, 'yyyy-MM-dd')
    apiCall(`/admins/users/${user.id}/accounts/${account.accountNo}/transactions?fromDate=${from}&toDate=${to}`).then(x => setRows(x.response.map(x => {
      x.id = x.tranKey;
      return x
    })))
  }
  const downloadCsv = () => {
    const from = format(fromDate, 'yyyy-MM-dd')
    const to = format(toDate, 'yyyy-MM-dd')
    const filename = `statement_${account.accountNo}_${from}_${to}.csv`
    var processRow = function (row) {
      return columns.map(x => x.field === 'sign' ? (row[x.field] === 1 ? 'Credit' : 'Debit') : row[x.field]).join(',') + '\n';
    };

    var csvFile = columns.map(x => x.headerName).join(',') + '\n';
    for (var i = 0; i < rows.length; i++) {
      csvFile += processRow(rows[i]);
    }

    var blob = new Blob([csvFile], {type: 'text/csv;charset=utf-8;'});
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>

      <Grid container spacing={2}>
        {/* <Grid item xs={12} md={3}>
          <Autocomplete
            options={isAdmin?users:[usr]}
            getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
            onChange={(e, value) => loadAccounts(value)}
            renderInput={(params) => <TextField {...params} label="User"/>}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Autocomplete
            options={accounts}
            value={account}
            getOptionLabel={(option) => `${option.accountNo} ${option.module} (${option.currency})`}
            onChange={(e, value) => setAccount(value)}
            renderInput={(params) => <TextField {...params} label="Account"/>}
          />
        </Grid> */}
        {/* <Grid item xs={12} md={2}>
          <KeyboardDatePicker
            format="yyyy-MM-dd"
            label="From Date"
            value={fromDate}
            onChange={(date) => setFromDate(date)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <KeyboardDatePicker
            format="yyyy-MM-dd"
            label="From Date"
            value={toDate}
            onChange={(date) => setToDate(date)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid> */}
        {/* <Grid item xs={12} md={2} container>

          <ButtonGroup  variant="contained" color="primary">
            <Button color='primary' variant="contained" fullWidth
                    onClick={() => loadStatement()}>Get Statement</Button>
            <Button disabled={!rows.length} color="primary" size="small" onClick={() => downloadCsv()}>
              <GetAppIcon/>
            </Button>
          </ButtonGroup>
        </Grid> */}

        <Grid item xs={12}>
          <div style={{height: 400, width: '100%'}}>
            <DataGrid rows={rows} id='transKey' columns={columns} pageSize={25}/>
          </div>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
