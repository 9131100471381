import * as React from 'react';
import {useEffect, useState} from 'react';
import apiCall from "../../Api";
import {Button, Grid} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {useHistory} from "react-router";
import {makeStyles} from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

export default function EditAccount(props){
    props.setTitle('Edit Account');
    const history = useHistory();
    const classes = useStyles();
    const {id} = props.match.params;
    const [users, setUsers] = React.useState([]);
    const [currencies, setCurrencies]  = useState([])
    const [account, setAccount] = useState({id: null,accountType: '', currency: '', accountNo: ''});
    const load= ()=> apiCall('/admins/accounts/accounts/'+id).then(r=> {
        console.log(r.response);
        setAccount(r.response)
    });

    const [accountTypes,setAccountTypes] = useState([]);

    useEffect(()=>{
        apiCall('/admins/customers/status/APPROVED').then(x => setUsers(x.response));
        apiCall('/admins/currencies').then(x => setCurrencies(x.response));
        apiCall('/admins/account-types').then(x => setAccountTypes(x.response));
        load();
    },[]);

    const save = ()=>{
        apiCall(`/admin/accounts/accounts/${account.id}`, account, 'PUT').then(r=>history.goBack())
    };

    return(
        <div>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="accountType">Account Type</InputLabel>
                        <Select label="Account Type" value={account.accountType}
                                style={{width: 300}}
                                onChange={(p) => {
                                    account.accountType=p.target.value;
                                    setAccount({...account});
                                }}
                                inputProps={{
                                    name: 'Account Type',
                                    id: 'AccountType',
                                }}>
                            {accountTypes.map(i=> (
                                <MenuItem value={i.code}>{i.title}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="currency">Currency</InputLabel>
                        <Select label="currency" value={account.currency.trim()}
                                style={{width: 300}}
                                onChange={(p) => {
                                    account.currency=p.target.value;
                                    setAccount({...account});
                                }}
                                inputProps={{
                                    name: 'Currency',
                                    id: 'currency',
                                }}>
                            {currencies.map(i=> (
                                <MenuItem value={i.currency}>{i.currency}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Account No"
                        name="accountNo"
                        id="accountNo"
                        variant="outlined"
                        value={account.accountNo}
                        onChange={(p) => {
                            account.accountNo = p.target.value;
                            setAccount({...account})
                        }}
                    />
                </Grid>

            </Grid>
            <hr/>
            <Button variant="contained" color="primary" onClick={() => save()}
                    style={{background: '#4fa14f'}}>
                Save
            </Button>
            &nbsp;
            <Button variant="outlined" onClick={() => history.goBack()}>
                Cancel
            </Button>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '100%',
    },
}));
