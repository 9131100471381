import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from './Title';
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router";

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
});

export default function DashboardCart(props) {
  const classes = useStyles();
  const history = useHistory();
  const {Icon} = props;
  return (
    <React.Fragment>
      {!!Icon && (<Icon fontSize='large' color='primary'/>)}
      <Title>{props.title}</Title>
      <Typography component="p" variant="h4">
        {props.value}
      </Typography>
      <Typography color="textSecondary" className={classes.depositContext}>
        {props.date && `on ${props.date}`}
      </Typography>
      <div>
        <Link color="primary" href='#' onClick={() => history.push(props.link)}>
          View details
        </Link>
      </div>
    </React.Fragment>
  );
}
