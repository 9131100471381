import React, {useState} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom'
 
import SignInSide from "./login/SignInSide";
import Register from "./register";
import Home from "./home";
import MarketingNetwork from "./marketing-network"
import PrivacyNotice from "./privacy-notice"
import Agreement from "./agreement"
import AboutUs from "./about-us"
import Dashboard from "./dashboard/Dashboard";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import {makeStyles} from "@material-ui/core/styles";
import AppMenu from "./components/menu/AppMenu";
import Container from "@material-ui/core/Container";
import clsx from "clsx";
import PendingCustomerList from "./customers/pending-customers/PendingCustomerList";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Box from "@material-ui/core/Box";
import Copyright from "./copyright";
import PendingCustomerDetails from "./customers/pending-customers/PendingCustomerDetails";
import PendingAccountList from "./accounts/pending-accounts/PendingAccountList";
import PendingTransactionList from "./transactions/pending-transactions/PendingTransactionList";
import PendingTransactionDetails from "./transactions/pending-transactions/PendingTransactionDetails";
import PendingChargeList from "./transactions/pending-charges/PendingChargeList";
import PendingChargeDetails from "./transactions/pending-charges/PendingChargeDetails";
import StatementList from "./reports/statement/StatementList";
import CustomerDetails from "./customers/customer-details/customer-details";
import CustomerList from "./customers/customers/CustomerList";
import EditCard from "./accounts/cards/EditCard";
import Transfer from "./transfer/Transfer";
import TransferBetweenAccounts from "./transfer/TransferBetweenAccounts";
import TransferBetweenUsers from "./transfer/TransferBetweenUsers";
import OutgoingWireTransfer from "./transfer/OutgoingWireTransfer";
import AccountList from "./accounts/accounts/AccountList";
import CardList from "./accounts/cards/CardList";
import {UserGroupList} from "./master-data/user-groups/UserGroupList";
import CurrencyList from "./master-data/currencies/CurrencyList";
import FeeList from "./master-data/fee/FeeList";
import EditFee from "./master-data/fee/EditFee";
import CreateAccount from "./accounts/accounts/CreateAccount";
import EditAccount from "./accounts/accounts/EditAccount";
import ChargeRequest from "./transfer/ChargeRequest";
// import ResetPassword from "./administration/ResetPassword";
import Profile from "./my-profile";
import ChangePassword from "./change-password";
import CustomerReport from "./reports/customer-list/CustomerReport";
import RevenueReport from "./reports/revenue-list/RevenueReport";
import AccountTypes from "./master-data/accountTypes/AccountTypeList";
import EditAccountType from "./master-data/accountTypes/EditAccountType";
import CardTypeList from "./master-data/cardTypes/CardTypeList";
import EditCardType from "./master-data/cardTypes/EditCardType";
import Deposite from "./deposite";
import Wallet from "./wallet";
import DashboardMain from "./dashboard-main";
import Success from "./receipt/success";

function App() {
  const classes = useStyles()
 

  const [token, setToken] = useState(sessionStorage.getItem('token'));
  const [error, setError] = useState('');
  const [open, setOpen] = React.useState(true);
  const [pageTitle, setPageTitle] = React.useState("PeaceTreasury");
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const signin = (e, username, password) => {
    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({username, password})
    };
    fetch(window.API_HOST + '/admins/login', requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.status === 'FAILURE' || data.error) {
          setError(data.message);
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('user');
          setToken('');
        } else { 
          sessionStorage.setItem('token', data.response.token);
          if(data.response.user){
            sessionStorage.setItem('user', JSON.stringify(data.response.user));
          }
          setError('');
          setToken(data.response.token);
        
        }
      });
    e.preventDefault();
  }
  const logout = () => {
    setToken('');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
  }
  if (!token) 
  {return (
    <BrowserRouter>
          <Switch>
              <Route path="/" exact component={() => <Home setTitle={setPageTitle}/>}/>
              <Route path="/login" exact component={() => <SignInSide signin={signin} SignInSide setTitle={setPageTitle}/>}/>
              <Route path="/register" exact component={() => <Register setTitle={setPageTitle}/>}/>
              <Route path="/about-us" exact component={() => <AboutUs setTitle={setPageTitle}/>}/>
              <Route path="/privacy-notice" exact component={() => <PrivacyNotice setTitle={setPageTitle}/>}/>
              <Route path="/agreement" exact component={() => <Agreement setTitle={setPageTitle}/>}/>
              <Route path="/marketing-network" exact component={() => <MarketingNetwork setTitle={setPageTitle}/>}/>
 
         </Switch>
    </BrowserRouter>
  )
  }
 
  return (
    <BrowserRouter>
      <div className={clsx('App', classes.root)}>
        <CssBaseline/>
        <AppBar position="fixed" className={clsx(classes.appBar, open && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
            >
              <MenuIcon/>
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap
                        className={classes.title}>{pageTitle}</Typography>
            <IconButton color="inherit">
              <Badge badgeContent={0} color="secondary">
                <NotificationsIcon/>
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          {/*<div className={classes.toolbarIcon}>*/}
          {/*  <IconButton onClick={handleDrawerClose}>*/}
          {/*    <ChevronLeftIcon/>*/}
          {/*  </IconButton>*/}
          {/*</div>*/}
          <AppMenu logout={logout}/>
        </Drawer>

        <main className={classes.content}>
          <div className={classes.appBarSpacer}/>
          <Container maxWidth="lg" className={classes.container}>

            <Switch>
              <Route path="/dashboard1" exact component={() => <Dashboard setTitle={setPageTitle}/>}/>
              <Route path="/login" exact component={() => <SignInSide setTitle={setPageTitle}/>}/>
              <Route path="/customers" exact component={() => <CustomerList setTitle={setPageTitle}/>}/>
              <Route path="/customers/:id" exact component={(params) => <CustomerDetails {...params} setTitle={setPageTitle}/>}/>
              <Route path="/pending-customers" exact component={() => <PendingCustomerList setTitle={setPageTitle}/>}/>
              <Route path="/pending-customers/:id" exact component={(params) => <PendingCustomerDetails {...params} setTitle={setPageTitle}/>}/>
              <Route path="/pending-accounts" exact component={() => <PendingAccountList setTitle={setPageTitle}/>}/>
              <Route path="/accounts" exact component={() => <AccountList setTitle={setPageTitle}/>}/>
              <Route path="/deposite" exact component={() => <Deposite setTitle={setPageTitle}/>}/>
              <Route path="/accounts/create" exact component={() => <CreateAccount setTitle={setPageTitle}/>}/>
              <Route path="/accounts/:id" exact component={(params) => <EditAccount {...params} setTitle={setPageTitle}/>}/>
              <Route path="/cards" exact component={() => <CardList setTitle={setPageTitle}/>}/>
              <Route path="/cards/:id" exact component={(params) => <EditCard {...params} setTitle={setPageTitle}/>}/>
              <Route path="/pending-transactions" exact component={() => <PendingTransactionList setTitle={setPageTitle}/>}/>
              <Route path="/pending-transactions/:id" exact component={(params) => <PendingTransactionDetails {...params} setTitle={setPageTitle}/>}/>
              <Route path="/pending-charges" exact component={() => <PendingChargeList setTitle={setPageTitle}/>}/>
              <Route path="/pending-charges/:id" exact component={(params) => <PendingChargeDetails {...params} setTitle={setPageTitle}/>}/>
              <Route path="/statement" exact component={() => <StatementList setTitle={setPageTitle}/>}/>
              <Route path="/customer-report" exact component={() => <CustomerReport setTitle={setPageTitle}/>}/>
              <Route path="/revenue-report" exact component={() => <RevenueReport setTitle={setPageTitle}/>}/>
              <Route path="/transfer" exact component={()=><Transfer setTitle={setPageTitle}/>}/>
              <Route path="/transfer/accounts" exact component={()=><TransferBetweenAccounts setTitle={setPageTitle}/>}/>
              <Route path="/transfer/users" exact component={()=><TransferBetweenUsers setTitle={setPageTitle}/>}/>
              <Route path="/transfer/outgoing" exact component={()=><OutgoingWireTransfer setTitle={setPageTitle}/>}/>
              <Route path="/transfer/chargeRequest" exact component={()=><ChargeRequest setTitle={setPageTitle}/>}/>
              <Route path="/user-groups" exact component={()=><UserGroupList setTitle={setPageTitle}/>}/>
              <Route path="/currencies" exact component={()=><CurrencyList setTitle={setPageTitle}/>}/>
              <Route path="/fee" exact component={()=><FeeList setTitle={setPageTitle}/>}/>
              <Route path="/fee/:id" exact component={(params)=><EditFee {...params} setTitle={setPageTitle}/>}/>
              <Route path="/accountTypes" exact component={()=><AccountTypes  setTitle={setPageTitle}/>}/>
              <Route path="/accountTypes/:id" exact component={(params)=><EditAccountType {...params}  setTitle={setPageTitle}/>}/>
              <Route path="/cardTypes" exact component={()=><CardTypeList  setTitle={setPageTitle}/>}/>
              <Route path="/cardTypes/:id" exact component={(params)=><EditCardType {...params}  setTitle={setPageTitle}/>}/>
              <Route path="/MyProfile" exact component={()=><Profile setTitle={setPageTitle}/>}/>
              <Route path="/ChangePassword" exact component={()=><ChangePassword setTitle={setPageTitle}/>}/>
              <Route path="/Wallet" exact component={()=><Wallet setTitle={setPageTitle}/>}/>
              <Route path="/dashboard" exact component={()=><DashboardMain setTitle={setPageTitle}/>}/>
              <Route path="/success" exact component={()=><Success setTitle={setPageTitle}/>}/>
              {/* <Route path="/" exact component={()=><DashboardMain setTitle={setPageTitle}/>}/> */}

            </Switch>
            {/* <Box pt={4}>
              <Copyright/>
            </Box> */}
          </Container>
        </main>
        {/* <div style={{ backgroundColor: "#F8F8F8",
          padding: "20px",
          position: "fixed",
          left: "0",
          bottom: "0",
          height: "60px",
          width: "100%",}}>
          <Copyright/>
        </div> */}
      </div>
    </BrowserRouter>
  )
}

export const drawerWidth = 250

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: '#fffda5',
    color: '#393535'
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    background: '#393535',
    color: '#fff',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}))

export default App;
