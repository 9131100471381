import * as React from 'react';
import {useEffect, useState} from 'react';
import {DataGrid} from '@material-ui/data-grid';
import {Button} from "@material-ui/core";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import {useHistory} from "react-router";
import apiCall from "../../Api";
import AddCircle from "@material-ui/icons/AddCircle";
import {makeStyles} from "@material-ui/core/styles";


const initRows = [];

export default function DataTable(props) {
  const [rows, setRows] = useState(initRows);
  const history = useHistory();
  const classes = styles();
  const columns = [
    {field: 'id', headerName: 'ID', width: 70},
    {field: 'firstName', headerName: 'First name', width: 130},
    {field: 'lastName', headerName: 'Last name', width: 130},
    {
      field: 'age',
      headerName: 'Age',
      type: 'number',
      width: 90,
      valueGetter: (params) =>
        parseInt((new Date() - new Date(params.getValue('dateOfBirth') || '')) / 1000 / 60 / 60 / 30 / 365),
    },
    {
      field: 'fullName',
      headerName: 'Full name',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 360,
      valueGetter: (params) =>
        `${params.getValue('firstName') || ''} ${params.getValue('lastName') || ''}`,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      renderCell: (params) => (
        <Button
          onClick={() => history.push('/customers/' + params.getValue('id'))}><KeyboardArrowRightIcon/></Button>)
    },
  ];
  props.setTitle('Customers')
  useEffect(() => {
    apiCall('/admins/customers/status/APPROVED').then(x => setRows(x.response));
  }, []);
  return (
      <div style={{height: 400, width: '100%'}}>
        <div className={classes.searchContainer}>
          <Button
              className={classes.success}
              variant="contained"
              color="primary"
              startIcon={<AddCircle/>}
              onClick={() => history.push('/customers/0')}>Create Customer</Button>
        </div>
        <DataGrid rows={rows} columns={columns} pageSize={5}/>
      </div>
  );
}

const styles = makeStyles((theme) => ({
  searchContainer: {
    width: '100%',
    marginBottom: '10px',
  },
  success: {
    backgroundColor: '#5cb85c',
    "&:hover": {
      backgroundColor: '#4fa14f',
    }
  }
}));
