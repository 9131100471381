import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useHistory } from "react-router";
import Paper from "@material-ui/core/Paper";
import apiCall from "../../Api";
import TextField from "@material-ui/core/TextField";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { Grid } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
  },
  instructionsItem: {
    marginBottom: 20,
  },
  margin: {
    marginBottom: 10
  },
  comments: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));


const emptyTran = {
  "memo": "",
  "user": {
    firstName: '',
    lastName: '',
    nationalIdNo: ''
  },
  "status": "",
  "id": null,
  "bank": "",
  "amount": 0,
  "destinationIbanNo": "",
  "tranKey": null,
  "sourceAccountNo": "",
  "adminComment": null,
  "account": {
    accountNo: '',
    balance: null,
    currency: '',
    module: ''
  }
}


export default function PendingCustomerDetails(params) {
  const classes = useStyles();
  const history = useHistory();
  const [tran, setTran] = useState(emptyTran);
  const [error, setError] = useState('');
  const [comments, setComments] = useState('');
  params.setTitle('Pending Transaction Request Information')
  const {id} = params.match.params;
  useEffect(() => {
    apiCall('/admins/transactions-external/' + id).then(x => {
      setTran(x.response)
      setComments(x.response && x.response.adminComment)
    }).catch((e) => setError(e.message));
  }, []);
  const handleFinish = (status) => {
    apiCall('/admins/transactions-external/' + id, {
      adminComment: comments,
      status
    }, 'PUT').then(resp => resp.status === 'FAILURE' ? setError(resp.message) : history.push('/pending-transactions'));
  };
  console.log(error)

  const handleBack = () => {
    history.goBack();
  };

  return (
    <div className={classes.root}>


      <div>
        <Paper className={classes.instructions}>
          <Grid container className={classes.instructionsItem}>
            <Grid item xs={12} md={4} lg={4}><TextField label='First Name' value={tran.user.firstName}/></Grid>
            <Grid item xs={12} md={4} lg={4}><TextField label='Last Name' value={tran.user.lastName}/></Grid>
            <Grid item xs={12} md={4} lg={4}><TextField label='National Id No' value={tran.user.nationalIdNo}/></Grid>
          </Grid>
          <Grid container className={classes.instructionsItem}>

            <Grid item xs={12} md={4} lg={4}><TextField label='Source Account' value={tran.account.accountNo}/></Grid>
            <Grid item xs={12} md={4} lg={4}><TextField label='Source Module' value={tran.account.module}/></Grid>
            <Grid item xs={12} md={4} lg={4}><TextField label='Source Balance'
                                                        value={`${tran.account.balance} ${tran.account.currency}`}/></Grid>

          </Grid>
          <Grid container className={classes.instructionsItem}>

            <Grid item xs={12} md={4} lg={4}><TextField label='Destination IBAN' value={tran.destinationIbanNo}/></Grid>
            <Grid item xs={12} md={4} lg={4}><TextField label='Destination Bank' value={tran.bank}/></Grid>
          </Grid>
          <Grid container className={classes.instructionsItem}>
            <Grid item xs={12} md={4} lg={4}><TextField label='Transaction Amount' value={tran.amount}/></Grid>
          </Grid>
          <Grid container className={classes.instructionsItem}>
            <Grid item xs={12}>
              <TextField label='Admin Comments' value={comments} fullWidth multiline rows={4}
                         onChange={(e) => setComments(e.target.value)}/>
            </Grid>
          </Grid>
        </Paper>
        {error && <Alert className={classes.margin} severity="error">{error}</Alert>}
        <div>
          <Button onClick={handleBack} className={classes.button}>Back</Button>
          <Button className={classes.button} variant="contained" color="primary"
                  onClick={() => handleFinish('APPROVED')}><CheckIcon/> Approve</Button>
          <Button className={classes.button} variant="contained" color="secondary"
                  onClick={() => handleFinish('REJECTED')}><ClearIcon/> Reject</Button>
        </div>
      </div>

    </div>
  );
}
