import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "../appbar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import clsx from "clsx";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./overrides.css"; // change this to the file path of your overrides
import { Link } from "@material-ui/core";
import VerticalGap from "../microcomponents/vertical-gap";
import img1 from "../assets/images/home/1.jpg";
import img2 from "../assets/images/home/2.jpg";
import img3 from "../assets/images/home/3.jpg";
import img4 from "../assets/images/home/4.jpg";
import img5 from "../assets/images/home/5.jpg";
import img6 from "../assets/images/home/6.jpg";
import img7 from "../assets/images/home/7.jpg";
import useMediaQuery, { QUERY_XL } from "use/useMediaQuery";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ThumbUpAltOutlinedIcon from "@material-ui/icons/ThumbUpAltOutlined";
import LanguageOutlinedIcon from "@material-ui/icons/LanguageOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  child: {
    marginTop: "2%",
  },
  slid: {
    height: "400px",
  },

  paper: {
    marginRight: theme.spacing(2),
  },
  typo: {
    fontStyle: "italic",
    fontWeight: "bold",
  },
}));
export default function Home(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const isXl = useMediaQuery(QUERY_XL);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);

  return (
    <div className={classes.root}>
      <AppBar />

      <Grid container direction="column" spacing={2} className={classes.child}>
        <Grid item className={classes.child}>
          <Grid container justify="space-evenly">
            <Grid item>
              <Link href="/about-us" variant="body1" className={classes.link}>
                <Grid container direction="column" >
                  <Grid item>
                    <Grid container justify="center">
                      <Grid item>
                        <InfoOutlinedIcon fontSize="medium" />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption" className={classes.typo} align="center">
                      ABOUT US
                    </Typography>
                  </Grid>
                </Grid>
              </Link>
            </Grid>

            <Grid item>
              <Link
                href="/privacy-notice"
                variant="body1"
                className={classes.link}
              >
                <Grid container direction="column" >
                  <Grid item>
                    <Grid container justify="center">
                      <Grid item>
                        <NotificationsNoneIcon fontSize="medium" />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption" className={classes.typo} align="center">
                      PRIVACY NOTICE
                    </Typography>
                  </Grid>
                </Grid>
              </Link>
            </Grid>

            <Grid item>
              <Link href="/agreement" variant="body1" className={classes.link}>
                <Grid container direction="column" >
                  <Grid item>
                    <Grid container justify="center">
                      <Grid item>
                        <ThumbUpAltOutlinedIcon fontSize="medium" />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption" className={classes.typo} align="center">
                      AGREEMENT
                    </Typography>
                  </Grid>
                </Grid>
              </Link>
            </Grid>

            <Grid item>
              <Link
                href="/marketing-network"
                variant="body1"
                className={classes.link}
              >
                <Grid container direction="column" >
                  <Grid item>
                    <Grid container justify="center">
                      <Grid item>
                        <LanguageOutlinedIcon fontSize="medium" />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption" className={classes.typo} align="center">
                      MARKETING NETWORK
                    </Typography>
                  </Grid>
                </Grid>
              </Link>
            </Grid>
          </Grid>
        </Grid>
 
        <Grid item>
          <Grid container justify="center">
            <Grid item  xs={12}>
              <Carousel autoPlay={true} showThumbs={false}>
                <div >
                  <img className={!isXl && classes.slid} src={img3} />
                  {/* <p style={{height:50,opacity:"inherit" , fontSize: "medium"}} className="legend">Peace Treasury plans to improve the quality of life and fight poverty.</p> */}
                </div>

                <div >
                  <img className={!isXl && classes.slid} src={img2} />
                  {/* <p style={{height:50,opacity:"inherit" ,color:"orange",background:"transparent",fontSize: "medium"}} className="legend">We bring individuals, business corporations, civil societies and communities together to achieve the Sustainable Development Goals (SDGs).</p> */}
                </div>

                <div >
                  <img className={!isXl && classes.slid} src={img1} />
                  {/* <p style={{height:50,opacity:"inherit" ,color:"white",background:"transparent",fontSize: "medium"}} className="legend">There is a Monthly Fixed Income for the peace promoters!
1% profit per month for all your investment in Peace Treasury.
</p> */}
                </div>

                <div >
                  <img className={!isXl && classes.slid} src={img4} />
                  {/* <p style={{height:50,opacity:"inherit" ,color:"white",background:"transparent",fontSize: "medium"}} className="legend">
                 Zero hunger, good health and well-being, education for all, clean water and decent work are the ultimate goals we can reach together.
                    </p> */}
                </div>

                <div >
                  <img className={!isXl && classes.slid} src={img5} />
                  {/* <p style={{height:50,opacity:"inherit" , fontSize: "medium"}} className="legend"> 	Peace Treasury is supporting the peace organizations in accelerating progress towards their humanitarian goals.</p> */}
                </div>

                <div >
                  <img className={!isXl && classes.slid} src={img6} />
                  {/* <p style={{height:60,opacity:"inherit"  ,fontSize: "medium"}} className="legend">
                    Together with all our peace partners worldwide, we’re contributing to a more equitable and resilient world, today and for generations to come.
                    </p> */}
                </div>

                <div >
                  <img className={!isXl && classes.slid} src={img7} />
                  {/* <p style={{height:50,opacity:"inherit" , fontSize: "medium"}} className="legend">
                    We help our friends invest to build savings that serve them throughout their lives along with contributing for peace projects worldwide.
                    </p> */}
                </div>
              </Carousel>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
