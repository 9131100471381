import * as React from 'react';
import {useEffect, useState} from 'react';
import {DataGrid} from "@material-ui/data-grid";
import Button from "@material-ui/core/Button";
import {AddCircle} from "@material-ui/icons";
import {useHistory} from "react-router";
import apiCall from "../../Api";
import {makeStyles} from "@material-ui/core/styles";
import {useMediaQuery} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const initRows = [];

export function UserGroupList(props){
    const [userGroup, setUserGroup] = useState({id: null, title: ''});
    const [open, setOpen] = React.useState(false);
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [rows, setRows] = useState(initRows);
    const history = useHistory();
    const classes = styles();
    const create = ()=> {
        userGroup.id = null;
        userGroup.title = '';
        setUserGroup({...userGroup});
        openDialog();
    }
    const refresh = () => apiCall('/admins/user-groups').then(x => x.response && x.response.length && setRows(x.response));
    const columns = [
        {field: 'id', headerName: 'ID', width: 70},
        {
            field: 'title',
            headerName: 'Title',
            width: 300
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 300,
            renderCell: (params) => (
                <div>
                    <Button color="primary"
                            onClick={() => {
                                userGroup.id = params.getValue('id');
                                userGroup.title = params.getValue('title');
                                setUserGroup({...userGroup});
                                openDialog();
                            }}><EditIcon/></Button>
                    <Button color="secondary"
                            onClick={() => {
                                userGroup.id = params.getValue('id');
                                userGroup.title = params.getValue('title');
                                setUserGroup({...userGroup});
                                deleteConfirm();
                            }}><DeleteIcon/></Button>
                </div>
                )
        }
    ];
    props.setTitle('User Groups');
    useEffect(() => {
        refresh()
    }, []);
    const openDialog = ()=>{
            setOpen(true);
    }
    const save = () =>{
        let url = '/admins/user-groups'
        if(userGroup.id)
            url += '/'+userGroup.id;
        apiCall(url, {title: userGroup.title}, userGroup.id?'PUT':'POST').then(r=>{
           if(r.status === 'SUCCESS'){
               handleClose();
               refresh();
           }
        });
    }
    const deleteConfirm = () => {
        setOpenConfirm(true)
    }

    const deleteItem = () =>{
        apiCall('/admins/user-groups/'+userGroup.id, {}, 'DELETE').then(r=> {
            setOpenConfirm(false)
            refresh();
        })
    }
    const handleClose = () => {
        setOpen(false)
    }
    const handleCloseConfirm = ()=>{
        setOpenConfirm(false)
    }
    return(
        <div style={{height: 400, width: '100%'}}>
            <div className={classes.searchContainer}>
                <Button
                    className={classes.success}
                    variant="contained"
                    color="primary"
                    startIcon={<AddCircle/>}
                    onClick={create}>Create User Group</Button>
            </div>
            <DataGrid rows={rows} columns={columns} pageSize={5}/>
            <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
                <DialogTitle id="responsive-dialog-title">{"Edit User Group"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <TextField
                            label="Title"
                            name="title"
                            id="init-balance"
                            variant="outlined"
                            value={userGroup.title}
                            onChange={(p) => {
                                userGroup.title = p.target.value;
                                setUserGroup({...userGroup})
                            }}
                            />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={save} variant="contained" color="primary">
                        Save
                    </Button>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog fullScreen={fullScreen} open={openConfirm} onClose={handleCloseConfirm} aria-labelledby="responsive-dialog-title">
                <DialogTitle id="responsive-dialog-title">{"Delete User Group?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={deleteItem} variant="contained" color="primary">
                        Yes
                    </Button>
                    <Button onClick={handleCloseConfirm} color="primary" autoFocus>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const styles = makeStyles((theme) => ({
    searchContainer: {
        width: '100%',
        marginBottom: '10px',
    },
    success: {
        backgroundColor: '#5cb85c',
        "&:hover": {
            backgroundColor: '#4fa14f',
        }
    }
}));
